<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <a-row>
        <a-col span="6" />
        <a-col span="18">
          <BjInput
            v-model="param.enter"
            rules="required"
            label-align="left"
            class="pr-40"
            tooltips
            placeholder="请输入组件标题"
          >
            <BjSwitch slot="suffix" v-model="param.enter_show" />
          </BjInput>
        </a-col>
      </a-row>
      <p :class="$style.title" class="mt-20">
        <span class="required">*</span>
        主要活动
      </p>
      <div :class="$style.box">
        <div :class="$style.boxAdd" @click="onAddMain()">
          <i class="ri-add-line" />
          关联数据
        </div>
        <div v-if="param.data.main && param.data.main.id" :class="$style.boxEdit">
          <div :class="$style.img">
            <img :src="param.data.main.cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <div class="ellipsis" :title="param.data.main.name">{{ param.data.main.name }}</div>
            <div class="ellipsis2 tip" :title="param.data.main.summary">
              {{ param.data.main.summary }}
            </div>
          </div>
          <div :class="$style.btn">
            <i class="ri-delete-bin-7-line" @click="onDeleteMain()" />
          </div>
        </div>
      </div>

      <p :class="$style.title" class="mt-20">次要活动</p>
      <div :class="$style.box">
        <div v-if="dataAddEffective" :class="$style.boxAdd" @click="onAdd()">
          <i class="ri-add-line" />
          关联数据
        </div>
        <div v-for="(item, index) in param.data.secondary" :key="item.id" :class="$style.boxEdit">
          <div :class="$style.img">
            <img :src="item.cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <div class="ellipsis" :title="item.name">{{ item.name }}</div>
            <div class="ellipsis2 tip" :title="item.summary">
              {{ item.summary }}
            </div>
          </div>
          <div :class="$style.btn">
            <i class="ri-delete-bin-7-line" @click="onDelete(item, index)" />
          </div>
        </div>
      </div>
    </BjForm>
    <chooseActivity :visible.sync="visible.main" :select="param.data.main" @change="mainChange" />
    <chooseActivity
      :visible.sync="visible.choose"
      :multiple="true"
      :select="param.data.secondary"
      @change="contentChange"
    />
  </div>
</template>

<script>
import chooseActivity from '@/components/chooseActivity.vue'

export default {
  name: 'notice',
  components: {
    chooseActivity,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        choose: false,
        main: false,
      },
    }
  },
  computed: {
    dataAddEffective() {
      return this.param.data.secondary.length < 4
    },
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.data)
    },
    contentChange(data) {
      this.param.data.secondary = data.splice(0, 4)
      this.onChange()
    },
    mainChange(data) {
      this.param.data.main = data[0]
      this.onChange()
    },
    onAdd() {
      this.visible.choose = true
    },
    onAddMain() {
      this.visible.main = true
    },
    onDelete(item, index) {
      this.param.data.secondary.splice(index, 1)
      this.onChange()
    },
    onDeleteMain() {
      this.param.data.main = null
      this.onChange()
    },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      padding: 10px;
      font-size: 12px;
      min-width: 0;

      .grey {
        color: #5c5c5c;
      }
    }

    .btn {
      width: 25px;

      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  .title {
    color: #000;
    font-size: 14px;
  }
}
</style>
