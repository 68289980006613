<template>
  <div :class="$style.notice" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" :class="$style.more">
        更多
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div v-if="mainShow" :class="$style.main">
      <span :class="$style.state">{{ data.data.main.activity_status | stateName }}</span>
      <img :src="data.data.main.cover.cover" />
      <p :class="$style.name" class="ellipsis">{{ data.data.main.name }}</p>
      <p :class="$style.summary" class="ellipsis2">{{ data.data.main.summary }}</p>
    </div>
    <div v-else :class="$style.maindemo">
      <span :class="$style.state">进行中</span>
      <img :src="oss + '/manage/media/assets/icon.png'" />
      <p :class="$style.name">主要活动的标题</p>
      <p :class="$style.summary" class="ellipsis2">
        主要活动的活动描述信息，最多显示两排。主要活动的活动描述信息，最多显示两排。
      </p>
    </div>
    <div v-if="otherShow" :class="$style.other">
      <img :src="data.data.secondary[0].cover.cover" />
      <div :class="$style.text">
        <p :class="$style.name">{{ data.data.secondary[0].name }}</p>
        <p :class="$style.summary" class="ellipsis2">{{ data.data.secondary[0].summary }}</p>
      </div>
    </div>
    <div v-else :class="$style.otherdemo">
      <div :class="$style.img">
        <img :src="oss + '/manage/media/assets/icon.png'" />
      </div>
      <div :class="$style.text">
        <p :class="$style.name">次要活动</p>
        <p :class="$style.summary" class="ellipsis2">
          次要活动的活动描述信息，最多显示两排。次要活动的活动描述信息，最多显示两排。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activity',
  filters: {
    stateName(val) {
      return val === 1 ? '未开始' : val === 2 ? '进行中' : '已结束'
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    mainShow() {
      return this.data.data.main && this.data.data.main.id
    },
    otherShow() {
      return !!this.data.data.secondary.length
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.notice {
  padding: 20px;

  .head {
    height: 46px;
    margin: 0;
    padding-bottom: 14px;

    .title {
      display: inline-block;
      width: 60%;
      color: #000;
      font-size: 18px;
    }

    .more {
      float: right;
      color: #5c5c5c;
      font-size: 12px;
      margin-top: 6px;
    }
  }

  .main {
    position: relative;
    width: 100%;
    height: 272px;
    background: #fff;

    img {
      width: 100%;
      height: 180px;
      border-radius: 6px 6px 0 0;
      object-fit: cover;
    }

    .name {
      margin-top: 14px;
      margin-bottom: 6px;
      padding: 0 14px;
      color: #000;
      font-size: 16px;
    }

    .summary {
      padding: 0 14px;
      color: #5c5c5c;
      font-size: 13px;
    }

    .state {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: @primary-color;
      font-size: 12px;
      border-radius: 0 6px 0 6px;
      padding: 2px 4px;
    }
  }
  .maindemo {
    position: relative;
    width: 100%;
    height: 272px;
    background: #eee;

    img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      object-fit: cover;
      margin: 65px 150px;
    }

    .name {
      padding: 14px 14px 6px;
      color: #000;
      font-size: 16px;
      background: #fff;
      margin: 0;
    }

    .summary {
      padding: 0 14px;
      color: #5c5c5c;
      font-size: 13px;
      background: #fff;
      height: 48px;
      margin: 0;
    }

    .state {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: @primary-color;
      font-size: 12px;
      border-radius: 0 6px 0 6px;
      padding: 2px 4px;
    }
  }
  .other {
    position: relative;
    display: flex;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 10px;
    background: #fff;

    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 6px;
      object-fit: cover;
    }

    .text {
      flex: 1;
    }

    .name {
      margin-bottom: 6px;
      color: #000;
      font-size: 14px;
    }

    .summary {
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .otherdemo {
    position: relative;
    display: flex;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 10px;
    background: #fff;

    .img {
      width: 60px;
      height: 60px;
      border-radius: 6px;
      background: #eee;
      text-align: center;
      margin-right: 10px;
    }

    img {
      margin-top: 12px;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      object-fit: cover;
    }

    .text {
      flex: 1;
    }

    .name {
      margin-bottom: 6px;
      color: #000;
      font-size: 14px;
    }

    .summary {
      color: #5c5c5c;
      font-size: 12px;
    }
  }
}
</style>
