var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":function($event){return _vm.onChoose()}}},[(_vm.data.title_show)?_c('p',{class:_vm.$style.head},[(_vm.data.title_show)?_c('span',{staticClass:"ellipsis",class:_vm.$style.title},[_vm._v(_vm._s(_vm.data.title))]):_vm._e()]):_vm._e(),(_vm.data.swiper === 0)?_c('div',{class:_vm.$style.default,style:({ height: _vm.height + 'px', padding: _vm.data.style === 0 ? '20px' : 0 })},[_c('div',{class:_vm.$style.box,style:({
        height: _vm.data.style === 0 ? _vm.height - 40 + 'px' : _vm.height + 'px',
        'border-radius': _vm.data.style === 0 ? '6px' : 0,
      })},[(_vm.data.data && _vm.data.data.length && _vm.data.swiper_title_show)?_c('div',{class:_vm.$style.tip,style:({ 'font-size': _vm.tipSize })},[_c('p',{staticClass:"ellipsis-width2"},[_vm._v(_vm._s(_vm.data.data[0].title))])]):_vm._e(),(_vm.data.data && _vm.data.data.length)?_c('img',{attrs:{"src":_vm.data.data[0].data.cover}}):_c('div',{class:_vm.$style.fake},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.oss + '/manage/media/assets/icon.png'}})]),(_vm.data.data.length > 1 && _vm.data.mode === 0)?_c('div',{class:[_vm.$style.btn, _vm.data.style === 0 ? _vm.$style.docSmall : null]},_vm._l((_vm.data.data),function(item,index){return _c('span',{key:index,class:_vm.$style.doc})}),0):_vm._e(),(_vm.data.data.length > 1 && _vm.data.mode === 1)?_c('div',{class:[_vm.$style.btnMode, _vm.data.style === 0 ? _vm.$style.modeSmall : null]},_vm._l((_vm.data.data),function(item,index){return _c('span',{key:index,class:_vm.$style.docMode})}),0):_vm._e()])]):_c('div',{class:_vm.$style.window,style:({ height: _vm.height + 'px' })},[_c('div',{class:_vm.$style.box},[_c('div',{class:_vm.$style.center,style:({
          height: _vm.height + 'px',
        })},[(_vm.data.data && _vm.data.data.length && _vm.data.swiper_title_show)?_c('div',{class:_vm.$style.tip,style:({ 'font-size': _vm.tipSize })},[_c('p',{staticClass:"ellipsis-width2"},[_vm._v(_vm._s(_vm.data.data[0].title))])]):_vm._e(),(_vm.data.data && _vm.data.data.length)?_c('img',{attrs:{"src":_vm.data.data[0].data.cover}}):_c('div',{class:_vm.$style.fake},[_c('img',{class:_vm.$style.img,attrs:{"src":_vm.oss + '/manage/media/assets/icon.png'}})])]),(_vm.data.data.length > 1)?_c('div',{class:_vm.$style.right,style:({
          width: 350 / (_vm.height / (_vm.height - 20)) + 'px',
          height: _vm.height - 20 + 'px',
          right: -(350 / (_vm.height / (_vm.height - 20))) + 10 + 'px',
        })},[(_vm.data.swiper_title_show)?_c('div',{class:_vm.$style.tip,style:({ 'font-size': _vm.tipSize })},[_c('p',{staticClass:"ellipsis-width2"},[_vm._v(_vm._s(_vm.data.data[0].title))])]):_vm._e(),_c('img',{attrs:{"src":_vm.data.data[1].data.cover}})]):_vm._e(),(_vm.data.data.length > 2)?_c('div',{class:_vm.$style.left,style:({
          width: 350 / (_vm.height / (_vm.height - 20)) + 'px',
          height: _vm.height - 20 + 'px',
          left: -(350 / (_vm.height / (_vm.height - 20))) + 10 + 'px',
        })},[(_vm.data.swiper_title_show)?_c('div',{class:_vm.$style.tip,style:({ 'font-size': _vm.tipSize })},[_c('p',{staticClass:"ellipsis-width2"},[_vm._v(_vm._s(_vm.data.data[0].title))])]):_vm._e(),_c('img',{attrs:{"src":_vm.data.data[1].data.cover}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }