<template>
  <div :class="$style.news" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" class="ellipsis" :class="$style.more">
        <span>{{ data.enter }} </span>
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div :class="$style.box">
      <div v-for="(item, index) in data.data" :key="index" :class="[!index ? $style.itemActive : $style.item]">
        {{ item.name }}
      </div>
    </div>
    <div :class="$style.list">
      <div v-for="(item, index) in listData" :key="index" :class="$style.item">
        <div :class="$style.left">
          <p :class="$style.title" class="ellipsis-width2">{{ item.title }}</p>
          <p :class="$style.time">{{ item.publish_time }}</p>
        </div>
        <div :class="$style.right">
          <div v-if="item.is_top" :class="$style.top">
            <i class="ri-arrow-up-line" />
            置顶
          </div>
          <img :src="item.cover.cover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showData: [
        {
          title: '这里显示新闻资讯的标题，最多显示两排。',
          publish_time: '2011-11-11',
          is_top: 1,
          cover: {
            cover: this.oss + '/manage/media/assets/icon.png',
          },
        },
      ],
    }
  },
  computed: {
    listData() {
      return this.data.data[0].news.length
        ? this.data.data[0].news
        : this.showData.concat(this.showData).concat(this.showData).concat(this.showData)
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.news {
  .head {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 14px 20px;

    .title {
      flex: 1;
      color: #000;
      font-size: 18px;
    }

    .more {
      max-width: 150px;
      padding-right: 15px;
      color: #5c5c5c;
      font-size: 12px;
      line-height: 25px;
      text-align: right;

      i {
        position: absolute;
        right: 20px;
        font-size: 17px;
        vertical-align: -4px;
      }
    }
  }

  .box {
    display: flex;
    margin-bottom: 4px;
    padding: 1px 20px;

    .item {
      width: 74px;
      height: 28px;
      margin-left: 20px;
      color: #000;
      line-height: 28px;
      text-align: center;
      border: 1px solid #eee;
      border-radius: 100px;
    }

    .item-active {
      width: 74px;
      height: 28px;
      color: #fff;
      line-height: 28px;
      text-align: center;
      background: @primary-color;
      border-radius: 100px;
    }
  }

  .list {
    padding: 0 20px;

    .item {
      display: flex;
      justify-content: space-between;
      height: 100px;
      padding: 10px 0;
      border-bottom: 1px solid #eee;

      .left {
        width: 226px;

        .title {
          height: 44px;
          margin-bottom: 0;
          color: #000;
          font-size: 16px;
        }

        .time {
          margin-top: 20px;
          margin-bottom: 0;
          color: #5c5c5c;
          font-size: 12px;
        }
      }

      .right {
        position: relative;

        img {
          width: 110px;
          height: 80px;
          border-radius: 6px;
          object-fit: cover;
        }

        .top {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 17px;
          font-size: 12px;
          line-height: 17px;
          background: #ffd287;
          border-radius: 0 4px 0 4px;

          i {
            font-size: 12px;
            vertical-align: 0;
          }
        }
      }
    }
  }
}
</style>
