<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        label-align="left"
        tooltips
        class="pr-40"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <a-row>
        <a-col span="6" />
        <a-col span="18">
          <BjInput
            v-model="param.enter"
            rules="required"
            label-align="left"
            class="pr-40"
            tooltips
            placeholder="请输入组件标题"
          >
            <BjSwitch slot="suffix" v-model="param.enter_show" />
          </BjInput>
        </a-col>
      </a-row>
      <BjValidationItem class="mt-20" rules="required" label-align="left" label="排序方式" v-bind="layout">
        <a-radio-group v-model="param.sort" @change="onChange">
          <a-radio :style="radioStyle" :value="0"> 用户位置与内容距离（近-远） </a-radio>
          <a-radio :style="radioStyle" :value="1"> 内容阅读数量（多-少） </a-radio>
          <a-radio :style="radioStyle" :value="2"> 内容发布时间（近-远） </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem class="mt-20" rules="required" label-align="left" label="内容加载" v-bind="layout">
        <a-radio-group v-model="param.load_mode" @change="onChange">
          <a-radio :style="radioStyle" :value="0"> 页面滑动加载 </a-radio>
          <a-radio :style="radioStyle" :value="1"> 点击按钮加载 </a-radio>
        </a-radio-group>
        <p class="tip">如果设置为页面滑动加载，建议将此组件放置在页面最底部。</p>
      </BjValidationItem>
      <BjValidationItem class="mt-20" rules="required" label-align="left" label="内容来源" v-bind="layout">
        <a-radio-group v-model="param.source" @change="onChange">
          <a-radio :value="0" :disabled="editor.page_type === 'poc'"> 全局数据 </a-radio>
          <a-radio :value="1" :disabled="editor.page_type !== 'poc'"> 文化点关联数据 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <p v-if="param.source === 1" :class="$style.title">
        <span class="required">*</span>
        配置内容
      </p>
      <div v-if="param.source === 1" :class="$style.box">
        <div :class="$style.boxAdd" @click="onAdd()">
          <i class="ri-add-line" />
          关联内容
        </div>
        <div v-for="(item, index) in param.data" :key="item.id" :class="$style.boxEdit">
          <div :class="$style.img">
            <img :src="item.cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <div class="ellipsis2">{{ item.title }}</div>
            <div class="ellipsis-width tip" :title="'类型：' + item.type_name + '分组：' + item.group_name">
              <span :class="$style.grey">类型：</span>
              <span :class="$style.val">{{ item.type_name }}</span>
              <span :class="$style.grey" class="ml-5">分组：</span>
              <span :class="$style.val">{{ item.group_name }}</span>
            </div>
          </div>
          <div v-if="editor.page_type === 'poc'" :class="$style.btn">
            <i class="ri-delete-bin-7-line" @click="onDelete(item, index)" />
          </div>
        </div>
      </div>
    </BjForm>
    <chooseContent :visible.sync="visible.choose" :select="param.data" @change="contentChange" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import chooseContent from '@/components/chooseContent.vue'
import { manageService } from '@/service'

const service = new manageService()

export default {
  name: 'contentList',
  components: {
    chooseContent,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        choose: false,
      },
      radioStyle: {
        display: 'block',
        height: '24px',
        lineHeight: '24px',
      },
    }
  },
  computed: {
    ...mapState(['editor']),
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
        if (this.editor.page_type === 'poc') {
          this.param.source = 1
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.data)
    },
    async contentChange(data) {
      try {
        await service.relationPoc({
          poc_id: this.editor.poc_id,
          content_id: data[0].id,
        })
        this.param.data.push(data[0])
        this.onChange()
      } catch (e) {}
    },
    onAdd() {
      this.visible.choose = true
      this.editIndex = null
    },
    async onDelete(item, index) {
      try {
        await service.relationPocDelete({
          poc_id: this.editor.poc_id,
          content_id: item.id,
        })
        this.param.data.splice(index, 1)
        this.onChange()
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      padding: 10px;
      font-size: 12px;

      .grey {
        color: #5c5c5c;
      }
    }

    .btn {
      width: 25px;

      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  .title {
    color: #000;
    font-size: 14px;
  }
}
</style>
