<template>
  <div :class="$style.head" @click="onChoose()">
    <div :class="$style.top" :style="style">
      <i :class="$style.home" class="ri-home-6-line" />
      <img
        v-if="data.style === 1 && cover[0].type === 'video'"
        :class="$style.play"
        :src="oss + '/manage/icon/default/video.png'"
      />
      <div v-if="data.style === 1 && cover[0].type === 'video'" :class="$style.switch">
        <div :class="$style.switchActive">视频</div>
        <div>图片</div>
      </div>
      <div v-if="data.style === 1" :class="$style.num">1/{{ cover.length }}</div>
      <div v-if="data.style === 0" :class="$style.more">
        <div v-for="(item, index) in cover.slice(0, 3)" :key="index" :class="$style.item">
          <img :src="item.cover" alt="" />
        </div>
        <div>{{ cover.length }}张</div>
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.box">
        <div :class="$style.left">
          <p :class="$style.title" class="ellipsis-width">{{ data.info.name }}</p>
          <p class="tip ellipsis-width">同义词: {{ data.info.synonym || '无' }}</p>
        </div>
        <div :class="$style.right">
          <img src="@/assets/map.png" alt="" />
          <div :class="$style.text">
            <i class="ri-map-pin-2-line" />
            0.2km
          </div>
        </div>
      </div>
      <div :class="$style.summary">
        <p :class="$style.text">{{ data.info.summary }}</p>
        <div :class="$style.btn">
          查看文化信息
          <i class="ri-arrow-right-s-line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'pocHeader',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      cover: [],
    }
  },
  computed: {
    style() {
      return {
        'background-image': 'url(' + this.cover[0].cover + ')',
      }
    },
  },
  watch: {
    data: {
      handler(val) {
        this.cover = _.cloneDeep(val.info.cover_atlas)
        let video = []
        video = this.cover.filter(item => item.type === 'video')
        if (video.length) {
          this.cover = video.concat(this.cover.filter(item => item.type === 'image'))
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.head {
  .top {
    position: relative;
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .home {
    position: absolute;
    top: 54px;
    left: 20px;
    color: #fff;
    font-size: 24px;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
  }

  .switch {
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: flex;
    width: 80px;
    height: 22px;
    color: #000;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 100px;
    transform: translate(-50%, 0%);

    &-active {
      width: 40px;
      color: #fff;
      background: @primary-color;
      border-radius: 100px;
    }

    div {
      width: 40px;
    }
  }

  .num {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 44px;
    height: 24px;
    color: #fff;
    line-height: 24px;
    text-align: center;
    background: #000;
    border-radius: 20px;
  }

  .more {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 40px;
    padding: 0 6px;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    background: #000;
    border-radius: 100px;

    .item {
      width: 14px;
      height: 14px;
      margin: 0 2px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content {
    width: 100%;
    height: 20 0;

    .box {
      display: flex;
      height: 70px;

      .left {
        flex: 1;
        padding: 18px 0 0 20px;
      }

      .right {
        position: relative;
        width: 120px;
        height: 70px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .text {
          position: absolute;
          top: 22px;
          left: 30px;
          color: @primary-color;
          font-size: 14px;

          i {
            color: @primary-color;
            font-size: 18px;
          }
        }
      }
    }

    .title {
      margin-bottom: 8px;
      color: #000;
      font-size: 18px;
    }
  }

  .summary {
    position: relative;
    margin: 20px 20px 0 20px;
    /* stylelint-disable-next-line no-descending-specificity */
    .text {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .btn {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40px;
      color: #000;
      line-height: 60px;
      text-align: center;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fafafa);
      /* stylelint-disable-next-line no-descending-specificity */
      i {
        font-size: 17px;
      }
    }
  }
}
</style>
