<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        tooltips
        class="pr-40"
        label-align="left"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <a-row>
        <a-col span="6" />
        <a-col span="18">
          <BjInput v-model="param.enter" class="pr-40" rules="required" tooltips placeholder="请输入组件标题">
            <BjSwitch slot="suffix" v-model="param.enter_show" />
          </BjInput>
        </a-col>
      </a-row>
      <BjValidationItem label-align="left" class="mt-20" rules="required" label="显示样式" v-bind="layout">
        <a-radio-group v-model="param.style" @change="onChange">
          <a-radio :value="0"> 样式一 </a-radio>
          <a-radio :value="1"> 样式二 </a-radio>
          <a-radio :value="2"> 样式三 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem label-align="left" class="mt-20" rules="required" label="内容来源" v-bind="layout">
        <a-radio-group v-model="param.source" @change="onChange">
          <a-radio :value="0"> 热门文化点 </a-radio>
          <a-radio :value="1"> 自定义数据 </a-radio>
        </a-radio-group>
        <p class="tip">热门文化点将显示访问量最高的文化点，内容中最多显示10条。</p>
      </BjValidationItem>
      <p v-if="param.source === 1" :class="$style.title">
        <span class="required">*</span>
        选择文化点
      </p>
      <div v-if="param.source === 1" :class="$style.box">
        <div :class="[$style.boxAdd, param.data.length > 9 ? $style.disable : null]" @click="onAdd()">
          <i class="ri-add-line" />
          选择文化点
        </div>
        <div :class="$style.line" />
        <div v-for="(item, index) in param.data" :key="item.id" :class="$style.boxEdit">
          <div :class="$style.img">
            <img v-if="item.cover" :src="item.cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <div class="ellipsis-width">{{ item.name }}</div>
            <div class="ellipsis2 tip">
              {{ item.summary }}
            </div>
          </div>
          <div :class="$style.btn">
            <i class="ri-delete-bin-7-line" @click="onDelete(index)" />
          </div>
        </div>
      </div>
    </BjForm>
    <choosePoint :visible.sync="visible.choose" :multiple="true" :select="param.data" @change="contentChange" />
  </div>
</template>

<script>
import _ from 'lodash'

import choosePoint from '@/components/choosePoint.vue'

export default {
  name: 'card',
  components: {
    choosePoint,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        choose: false,
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.param)
    },
    contentChange(data) {
      if (data.length > 10) {
        this.$message.error('最多只能添加10条文化点数据')
        return
      }
      this.param.data = _.cloneDeep(data)
      this.onChange()
    },
    onAdd() {
      if (this.param.data.length > 9) {
        return
      }
      this.visible.choose = true
      this.editIndex = null
    },
    onDelete(index) {
      this.param.data.splice(index, 1)
    },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;

    .line {
      width: 100%;
      margin: 10px 0;
      border-top: 1px solid #eee;
    }
  }

  .title {
    color: #000;
    font-size: 14px;
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;

    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      padding: 10px;
    }

    .btn {
      width: 25px;

      i {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
}

.disable {
  color: #bbb !important;
  cursor: not-allowed;
}
</style>
