<template>
  <div :class="$style.header">
    <div :class="$style.left">
      <a-tooltip placement="right">
        <template slot="title"> 退出编辑器 </template>
        <i class="ri-logout-box-line" @click="back()" />
      </a-tooltip>
    </div>
    <div :class="$style.center">
      <span
        :class="$style.title"
        :title="data.title && data.title.length > 10 ? data.title : null"
        class="ellipsis-width"
        >{{ data.title }}</span
      >
      <span :class="[$style.status, data.is_publish === 1 ? $style.statusActive : null]">
        <span :class="[$style.tag, data.is_publish === 1 ? $style.tagActive : null]" />
        {{ data.is_publish | state }}
      </span>
    </div>
    <div :class="$style.right">
      <div :class="$style.item">
        <div id="car" :class="$style.file">
          <BjFile :show-btn="false" />
        </div>
      </div>
      <div :class="$style.line" />
      <div :class="$style.item" @click="onSet()">
        <div :class="$style.file">
          <i class="ri-settings-line" />
        </div>
      </div>
      <div :class="$style.line" />
      <BjButton
        v-permission="'terminal.mobile.index_config-edit'"
        :class="$style.btn"
        class="btn-default mr-10"
        @click="save(true)"
      >
        <i class="ri-send-plane-2-line left" />立即发布
      </BjButton>
      <BjButton v-permission="'terminal.mobile.index_config-edit'" type="primary" @click="save()">
        <i class="ri-save-line left" />
        保存页面
      </BjButton>
    </div>
  </div>
</template>

<script>
import BjFile from '@/components/BjFile.vue'

export default {
  name: 'Header',
  filters: {
    state(val) {
      return val === 1 ? '已发布' : '未发布'
    },
  },
  components: {
    BjFile,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    save(publish) {
      this.$emit('save', !!publish)
    },
    onSet() {
      this.$emit('change', 'set')
    },
    back() {
      if (this.$store.state.editorBackName) {
        this.$router.push({
          path: this.$store.state.editorBackName,
        })
      } else {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
  },
}
</script>

<style lang="less" module>
.header {
  position: relative;
  z-index: 9;
  height: 60px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 60px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  .left {
    position: absolute;
    top: 0;
    left: 20px;
    display: inline-block;
    height: 60px;
    line-height: 68px;

    i {
      height: 24px;
      color: #000;
      font-size: 24px;
      line-height: 24px;
    }

    i:hover {
      color: @primary-color;
    }
  }

  .center {
    display: flex;
    justify-content: center;

    .title {
      color: #000;
      font-size: 16px;
    }

    .status {
      display: inline;
      margin-left: 10px;
      color: #5c5c5c;
      font-size: 13px;

      .tag {
        position: relative;
        top: -2px;
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        background: #5c5c5c;
        border-radius: 5px;
      }

      .tag-active {
        background: @primary-color;
      }
    }

    .status-active {
      color: @primary-color;
    }
  }

  .right {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    height: 60px;
  }

  .item {
    width: 32px;

    :global {
      .ant-badge {
        display: inline;
      }
    }
  }

  .file {
    width: 32px;
    height: 32px;
    color: #5c5c5c;
    line-height: 32px;
    text-align: center;
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 4px;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      font-size: 16px;
    }
  }

  .file:hover {
    color: @primary-color;
    border-color: @primary-color;
  }

  .line {
    width: 1px;
    height: 20px;
    margin: 0 10px;
    border-left: 1px solid #eee;
  }

  .btn {
    color: #5c5c5c;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      margin-right: 5px;
    }
  }

  .btn:hover {
    color: @primary-color;
  }
}
</style>
