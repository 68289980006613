<template>
  <div :class="$style.nav">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        label-align="left"
        tooltips
        class="pr-40"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <BjValidationItem rules="required" label-align="left" label="显示样式" v-bind="layout">
        <a-radio-group v-model="param.style" @change="onChange">
          <a-radio :value="0"> 一排 </a-radio>
          <a-radio :value="1"> 两排 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem rules="required" label-align="left" label="一行显示" v-bind="layout">
        <a-radio-group v-model="param.row_num" @change="onChange">
          <a-radio :value="0"> 五个 </a-radio>
          <a-radio :value="1"> 四个 </a-radio>
          <a-radio :value="2"> 三个 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem rules="required" label="导航配置">
        <div :class="$style.box">
          <div :class="$style.boxAdd" @click="onAdd()">
            <i class="ri-add-line mr-5" />
            添加导航
          </div>
          <div v-if="param.data.length" :class="$style.line" />
          <div v-for="(item, index) in param.data" :key="item.id" :class="$style.boxEdit">
            <div :class="$style.img">
              <img :src="item.data.cover" alt="" />
            </div>
            <div :class="$style.text">
              <div class="ellipsis-width">标题：{{ item.title }}</div>
              <div class="ellipsis-width">
                跳转：<span class="primary">{{ item.link.label }}</span>
              </div>
            </div>
            <div :class="$style.btn">
              <i class="ri-edit-2-line" @click="onEdit(item, index)" />
              <i class="ri-delete-bin-7-line" @click="onDelete(index)" />
            </div>
          </div>
        </div>
      </BjValidationItem>
    </BjForm>
    <bj-modal
      :title="title"
      :visible="editVisible"
      :width="550"
      :body-style="{ height: '520px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <BjForm ref="edit">
          <BjInput
            v-model="edit.title"
            label-align="left"
            v-bind="layout"
            rules="required"
            tooltips
            placeholder="请输入导航标题"
            label="导航标题"
          />
        </BjForm>
        <BjValidationItem rules="required" label-align="left" label="导航图标" v-bind="layout">
          <!-- <BjButton class="btn-default" @click="onAddImg()">
            <i class="ri-upload-line left" />
            选择图片
          </BjButton>
          <div v-if="edit.data && edit.data.cover" :class="$style.img">
            <img :src="edit.data.cover" alt="" />
          </div> -->
          <selectMaterial :allow="['image']" :data="edit.data" @change="swiperChange" />
          <p class="tip mt-10 mb-0">导航图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
        </BjValidationItem>
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddLink()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="edit.link" :class="$style.link">{{ edit.link.label }}</p>
          <p class="tip mt-10">请选择点击导航图标需要跳转的链接。</p>
        </BjValidationItem>
      </div>
    </bj-modal>
    <!-- <chooseMaterial :visible.sync="chooseVisible" :allow="['image']" @getChoose="getChoose" /> -->
    <chooseLink :visible.sync="linkVisible" :data="edit.link" @change="getLink" />
  </div>
</template>

<script>
import _ from 'lodash'

import chooseLink from '@/components/chooseLink'
// import chooseMaterial from '@/components/chooseMaterial'
import selectMaterial from '@/components/selectMaterial'

export default {
  name: 'navigation',
  components: {
    // chooseMaterial,
    chooseLink,
    selectMaterial,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      // chooseVisible: false,
      editVisible: false,
      linkVisible: false,
      title: '添加导航',
      edit: {
        data: {},
        link: null,
        title: null,
      },
      editIndex: 0,
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    swiperChange(data) {
      this.edit.data = { type: 'image', ...data[0] }
    },
    onAdd() {
      this.editVisible = true
      this.edit = {
        data: {},
        link: null,
        title: null,
      }
      this.editIndex = null
      this.title = '添加导航'
    },
    onEdit(item, index) {
      this.title = '编辑导航'
      this.editIndex = index
      this.edit = _.cloneDeep(item)
      this.editVisible = true
    },
    // onAddImg() {
    //   this.chooseVisible = true
    // },
    onAddLink() {
      this.linkVisible = true
    },
    // getChoose(data) {
    //   this.edit.data = { type: 'image', ...data[0] }
    // },
    getLink(data) {
      this.edit.link = _.cloneDeep(data)
    },
    onDelete(index) {
      this.param.data.splice(index, 1)
    },
    handleOk() {
      if (!this.edit.title || !this.edit.link || !this.edit.data.type) {
        this.$message.error('请把信息填完整后提交')
        return
      }
      if (this.editIndex !== null) {
        this.param.data.splice(this.editIndex, 1, this.edit)
      } else {
        this.param.data.push(this.edit)
      }
      this.onChange()
      this.handleCancel()
    },
    handleCancel() {
      this.editVisible = false
    },
    onChange() {
      this.$emit('change', this.param)
    },
  },
}
</script>

<style lang="less" module>
.modal {
  .img {
    position: relative;
    width: 70px;
    height: 70px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .link {
    margin-bottom: 0;
    color: @primary-color;
  }

  .tip {
    color: #5c5c5c;
    font-size: 12px;
  }
}

.nav {
  :global {
    .ant-form-item {
      margin-bottom: 20px;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;

    .line {
      width: 100%;
      margin: 10px 0;
      border-top: 1px solid #eee;
    }
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;

    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .img {
    position: relative;
    width: 70px;
    height: 70px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .text {
      flex: 1;
      padding: 10px;
      font-size: 12px;

      div {
        height: 25px;
        line-height: 25px;
      }
    }

    .btn {
      width: 60px;

      i {
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
}
</style>
