<template>
  <div :class="$style.head" :style="{ height: height }" @click="onChoose()">
    <div v-if="data.style === 0" :class="$style.one">
      <div :class="$style.box">
        <div :class="$style.back">
          <img v-if="data.background.type === 'image' && data.style_type === 0" :src="data.background.cover" />
          <video
            v-if="data.background.type === 'video' && data.style_type === 0"
            :class="$style.video"
            :src="data.background.path"
            autoplay="autoplay"
            muted
          />
          <div
            v-if="data.style_type === 1"
            :class="$style.backColor"
            :style="{ 'background-color': data.background_color }"
          />
        </div>

        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <div :class="[$style.input, $style.search]">
          <i class="ri-search-line" />
          <span class="ellipsis">{{ data.tip }}</span>
        </div>
        <div>
          <div :class="$style.img">
            <img v-if="data.data.length" :class="$style.banner" :src="data.data[0].data.cover" alt="" />
            <img v-else :class="$style.noimg" :src="oss + '/manage/media/assets/icon.png'" alt="" />
            <div v-if="data.data.length > 1" :class="$style.docMode">
              <span v-for="(item, index) in data.data" :key="index" :class="$style.doc" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.style === 1" :class="$style.two">
      <div :class="$style.box">
        <div :class="$style.back">
          <img v-if="data.background.type === 'image' && data.style_type === 0" :src="data.background.cover" />
          <video
            v-if="data.background.type === 'video' && data.style_type === 0"
            :class="$style.video"
            :src="data.background.path"
            autoplay="autoplay"
            muted
          />
          <div
            v-if="data.style_type === 1"
            :class="$style.backColor"
            :style="{ 'background-color': data.background_color }"
          />
        </div>
        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <div :class="$style.bm">
          <div :class="[$style.input, $style.search]">
            <i class="ri-search-line" />
            <span class="ellipsis">{{ data.tip }}</span>
          </div>
          <div :class="$style.img">
            <img v-if="data.data && data.data.length > 2" :class="$style.right" :src="data.data[2].data.cover" />
            <img v-if="data.data && data.data.length > 1" :class="$style.left" :src="data.data[1].data.cover" />
            <img v-if="data.data && data.data.length" :class="$style.banner" :src="data.data[0].data.cover" alt="" />
            <img v-else :class="$style.noimg" :src="oss + '/manage/media/assets/icon.png'" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.style === 2" :class="$style.three">
      <div
        :class="$style.box"
        :style="{
          backgroundImage: data.data[0] ? 'url(' + data.data[0].data.cover + ')' : 'url(' + defaultImg + ')',
          backgroundSize: data.data[0] ? null : '50px',
        }"
      >
        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <div :class="[$style.input, $style.search]">
          <i class="ri-search-line" />
          <span class="ellipsis">{{ data.tip }}</span>
        </div>
        <div v-if="data.data.length > 1" :class="$style.docMode">
          <span v-for="(item, index) in data.data" :key="index" :class="$style.doc" />
        </div>
      </div>
    </div>

    <div v-if="data.style === 3" :class="$style.four">
      <div :class="$style.box">
        <img
          v-if="data.style_type === 0 && data.background.type === 'image'"
          :class="$style.img"
          :src="data.background.cover"
        />
        <video
          v-if="data.style_type === 0 && data.background.type === 'video'"
          :class="$style.video"
          :src="data.background.path"
          autoplay="autoplay"
          muted
        />
        <div
          v-if="data.style_type === 1"
          :class="$style.biscuit"
          :style="{ 'background-color': data.background_color }"
        />
        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <p :class="$style.tip" class="ellipsis-width2" v-html="showBackTip || '这里显示<br/>顶部背景文案'" />
        <i
          v-if="data.style_type === 0 && data.background.type === 'video'"
          :class="$style.icon"
          class="ri-volume-off-vibrate-line"
        />
        <div :class="$style.block" />
      </div>
      <div :class="$style.ban">
        <img v-if="data.data.length" :class="$style.banner" :src="data.data[0].data.cover" />
        <img v-else :class="$style.noimg" :src="oss + '/manage/media/assets/icon.png'" />
        <div v-if="data.data.length > 1" :class="$style.docMode">
          <span v-for="(item, index) in data.data" :key="index" :class="$style.doc" />
        </div>
      </div>
    </div>

    <div v-if="data.style === 4" :class="$style.five">
      <div v-if="data.style_type === 0" :class="$style.box">
        <img
          v-if="data.style_type === 0 && data.background.type === 'image'"
          :class="$style.img"
          :src="data.background.cover"
        />
        <video
          v-if="data.style_type === 0 && data.background.type === 'video'"
          :class="$style.video"
          :src="data.background.path"
          autoplay="autoplay"
          muted
        />
        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <div v-if="data.nav.length" :class="$style.nav">
          <div
            v-for="(item, index) in data.nav"
            :key="index"
            :style="{ width: 100 / data.nav.length + '%' }"
            :class="$style.item"
          >
            <img :src="item.data.cover" />
            <p :class="$style.name" class="ellipsis-width">{{ item.title }}</p>
          </div>
        </div>
        <div v-else :class="$style.nav">
          <div v-for="(item, index) in navShow" :key="index" :style="{ width: '20%' }" :class="$style.item">
            <div :class="$style.block" />
            <p :class="$style.name" class="ellipsis-width">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div v-else :class="$style.box" :style="{ 'background-color': data.background_color }">
        <p :class="$style.title">
          <img :class="$style.logo" :src="user.cultural_cloud_logo" alt="" />
          {{ user.cultural_cloud_name }}
        </p>
        <div :class="$style.nav">
          <div
            v-for="(item, index) in data.nav"
            :key="index"
            :style="{ width: 100 / data.nav.length + '%' }"
            :class="$style.item"
          >
            <img :src="item.data.cover" />
            <p :class="$style.name" class="ellipsis-width">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div :class="$style.fix">
        <div :class="$style.ban">
          <img v-if="data.data.length" :class="$style.banner" :src="data.data[0].data.cover" alt="" />
          <img v-else :class="$style.noimg" :src="oss + '/manage/media/assets/icon.png'" alt="" />
        </div>
        <div v-if="data.data.length > 1"><img :src="data.data[1].data.cover" :class="$style.right" /></div>
        <div v-if="data.data.length > 2"><img :src="data.data[2].data.cover" :class="$style.left" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'heads',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultImg: this.oss + '/manage/media/assets/icon.png',
      navShow: [
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
      ],
    }
  },
  computed: {
    ...mapState(['user']),
    height() {
      return this.data.style === 2
        ? '220px'
        : this.data.style === 0 || this.data.style === 1
        ? '300px'
        : this.data.style === 4
        ? '395px'
        : '400px'
    },
    showBackTip() {
      return this.data.back_tip.replace(/\n/, '<br>')
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.head {
  height: 300px;

  .logo {
    border-radius: 100%;
  }

  .search {
    span {
      display: inline-block;
      width: 290px;
    }

    i {
      vertical-align: 10px;
    }
  }

  .one {
    .box {
      height: 220px;
      padding-top: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      // border-radius: 100% 100% 50% 50% ~'/' 0% 0% 20% 20%;
    }

    .back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 220px;
      overflow: hidden;
      border-radius: 100% 100% 50% 50% ~'/' 0% 0% 20% 20%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        width: 100%;
        height: 100%;
        object-fit: none;
      }
    }

    .back-color {
      width: 100%;
      height: 220px;
    }

    .full {
      background-size: 100% auto;
    }

    .title {
      position: relative;
      z-index: 1;
      height: 46px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      line-height: 44px;

      img {
        width: 24px;
        height: 24px;
        vertical-align: -5px;
      }
    }

    .input {
      position: relative;
      z-index: 1;
      width: 350px;
      height: 40px;
      margin: 30px auto 20px;
      line-height: 40px;
      background: #fff;
      border-radius: 100px;

      i {
        margin: 0 10px;
        color: #5c5c5c;
        font-size: 18px;
      }
    }

    .img {
      position: relative;
      width: 350px;
      height: 120px;
      margin: 0 auto;
      overflow: hidden;
      background: #eee;
      border-radius: 6px;

      .noimg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 50px;
        max-height: 50px;
        transform: translate(-50%, -50%);
      }

      .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .doc-mode {
        position: absolute;
        bottom: 0%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);

        .doc {
          display: inline-block;
          width: 5px;
          height: 5px;
          margin-right: 5px;
          line-height: 20px;
          background-color: #fff;
          border-radius: 5px;
        }

        .doc:first-child {
          width: 12px;
          background: @primary-color;
        }
      }
    }
  }

  .two {
    .box {
      height: 128px;
      padding-top: 44px;
    }

    .full {
      background-size: 100% auto;
    }

    .bm {
      position: relative;
      overflow: hidden;
      background: #fff;
      border-radius: 20px 20px 0 0;
    }

    .back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 128px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        width: 100%;
        height: 100%;
        object-fit: none;
      }
    }

    .back-color {
      width: 100%;
      height: 128px;
    }

    .title {
      height: 42px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      line-height: 42px;

      img {
        width: 24px;
        height: 24px;
        vertical-align: -5px;
      }
    }

    .input {
      width: 350px;
      height: 40px;
      margin: 20px auto 20px;
      line-height: 40px;
      background: #fff;
      border-radius: 100px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

      i {
        margin: 0 10px;
        color: #5c5c5c;
        font-size: 18px;
      }
    }

    .img {
      position: relative;
      width: 350px;
      height: 120px;
      margin: 0 auto;
      background: #eee;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }

      .noimg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 50px;
        max-height: 50px;
        transform: translate(-50%, -50%);
      }

      .left {
        position: absolute;
        top: 10px;
        left: -300px;
        width: 290px;
        height: 100px;
      }

      .right {
        position: absolute;
        top: 10px;
        right: -300px;
        width: 290px;
        height: 100px;
      }
    }
  }

  .three {
    .box {
      height: 220px;
      padding-top: 44px;
      background-color: #eee;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 100% 100% 80% 80% ~'/' 0% 0% 30% 30%;
    }

    .title {
      height: 42px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      line-height: 42px;

      img {
        width: 24px;
        height: 24px;
        vertical-align: -5px;
      }
    }

    .input {
      width: 350px;
      height: 40px;
      margin: 95px auto 20px;
      line-height: 40px;
      background: #fff;
      border-radius: 100px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

      i {
        margin: 0 10px;
        color: #5c5c5c;
        font-size: 18px;
      }
    }

    .doc-mode {
      position: absolute;
      bottom: 15%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);

      .doc {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        line-height: 20px;
        background-color: #fff;
        border-radius: 5px;
      }

      .doc:first-child {
        width: 12px;
        background: @primary-color;
      }
    }
  }

  .four {
    .box {
      position: relative;
      height: 280px;

      .img {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: none;
      }

      .title {
        position: absolute;
        top: 44px;
        height: 46px;
        padding: 0 20px;
        color: #fff;
        font-size: 16px;
        line-height: 44px;

        img {
          width: 24px;
          height: 24px;
          vertical-align: -5px;
        }
      }

      .tip {
        position: absolute;
        top: 178px;
        padding: 0 60px 0 20px;
        color: #fff;
        font-size: 20px;
      }

      .icon {
        position: absolute;
        right: 20px;
        bottom: 40px;
        color: #fff;
        font-size: 20px;
      }

      .block {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20px;
        background: #fff;
        border-radius: 20px 20px 0 0;
      }
    }

    .doc-mode {
      position: absolute;
      bottom: 0%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);

      .doc {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        line-height: 20px;
        background-color: #fff;
        border-radius: 5px;
      }

      .doc:first-child {
        width: 12px;
        background: @primary-color;
      }
    }

    .biscuit {
      height: 280px;
    }

    .ban {
      position: relative;
      width: 350px;
      height: 120px;
      margin: 0 auto;
      overflow: hidden;
      background: #eee;
      border-radius: 6px;

      .noimg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 50px;
        max-height: 50px;
        transform: translate(-50%, -50%);
      }

      .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .five {
    position: relative;
    height: 100%;
    overflow: hidden;

    .box {
      position: relative;
      height: 300px;
      overflow: hidden;
      border-radius: 100% 100% 50% 50% ~'/' 0% 0% 20% 20%;

      .img {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: none;
      }
    }

    .title {
      position: absolute;
      top: 44px;
      height: 46px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      line-height: 44px;

      img {
        width: 24px;
        height: 24px;
        vertical-align: -5px;
      }
    }

    .fix {
      position: absolute;
      bottom: 0;
      left: 20px;
    }

    .left {
      position: absolute;
      bottom: 10px;
      left: -325px;
      width: 315px;
      height: 180px;
      overflow: hidden;
      border-radius: 6px;
    }

    .right {
      position: absolute;
      right: -325px;
      bottom: 10px;
      width: 315px;
      height: 180px;
      overflow: hidden;
      border-radius: 6px;
    }

    .nav {
      position: absolute;
      top: 88px;
      left: 0;
      display: flex;
      width: 100%;
      padding-top: 20px;

      .item {
        text-align: center;

        img {
          width: 40px;
          height: 40px;
        }

        .block {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: #eee;
        }

        .name {
          margin-top: 10px;
          margin-bottom: 0;
          color: #fff;
          font-size: 12px;
        }
      }
    }

    .ban {
      position: relative;
      width: 350px;
      height: 200px;
      margin: 0 auto;
      overflow: hidden;
      background: #eee;
      border-radius: 6px;

      .noimg {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 50px;
        max-height: 50px;
        transform: translate(-50%, -50%);
      }

      .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
