<template>
  <a-layout :class="$style.layout" class="full-height">
    <Header :data="pageData" @save="save" @change="headerChange" />
    <div v-if="loading" :class="$style.loading">
      <a-spin />
    </div>
    <a-layout v-else class="flex-box" :class="$style.box">
      <Sidebar
        ref="sidebar"
        :editor-info="editorInfo"
        :content-data="contentData"
        @addItem="addItem"
        @active="active"
        @getContentData="getContentData"
      />
      <Content ref="content" :page-data="pageData" @change="change" @refreshList="getContentData" />
      <Detail :data="detailData" @change="change" />
    </a-layout>
    <bj-modal
      title="页面设置"
      :visible="visible.setting"
      :class="$style.set"
      :body-style="{ height: '400px', 'max-height': '600px', 'overflow-y': 'auto' }"
      @ok="setOk"
      @cancel="setCancel"
    >
      <BjForm ref="setForm">
        <BjInput
          v-model="modal.title"
          :disabled="editorInfo.page_type !== 'template'"
          label="页面标题"
          tooltips
          v-bind="layout"
          allow-clear
          label-align="left"
          rules="required"
          placeholder="请输入页面标题"
        />
        <a-row>
          <a-col span="6" />
          <a-col span="18" :class="$style.tip">
            当前页面类型为「前端首页」，默认显示文化云名称，不可编辑页面标题。
          </a-col>
        </a-row>
        <a-row class="mt-20">
          <a-col span="6">
            <span class="required">*</span>
            状态栏
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="modal.state_bar" :disabled="editorInfo.page_type === 'template'">
              <a-radio :value="0"> 黑色 </a-radio>
              <a-radio :value="1"> 白色 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="mt-10">
          <a-col span="6" />
          <a-col span="18" :class="$style.tip"> 设置状态栏样式，状态栏样式仅对小程序生效。 </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
      <div v-if="car" id="file" :class="$style.car">
        <img :src="oss + '/manage/media/assets/upload.png'" alt="" />
      </div>
    </transition>
  </a-layout>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import { editorService } from '@/service'
import { guid } from '@/utils'

import Content from './Content.vue'
import Detail from './Detail.vue'
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'

const service = new editorService()

export default {
  name: 'Home',
  components: {
    Header,
    Detail,
    Content,
    Sidebar,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchMenu())
    next()
  },
  data() {
    return {
      detailData: {},
      pageData: {}, // 后台存的数据 这个数据不能动 深拷贝出来处理
      visible: {
        setting: false,
      },
      info: {
        title: null,
        state_bar: 0,
      },
      modal: {
        title: null,
        state_bar: 0,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      loading: false,
      contentData: [],
    }
  },
  computed: {
    ...mapState(['car']),
    editorInfo: {
      get() {
        return this.$store.state.editor
      },
      set(val) {
        this.$store.commit('EDITOR', val)
      },
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    ...mapActions(['fetchMenu']),
    async getInfo() {
      try {
        this.loading = true
        let obj = this.editorInfo
        if (!obj.page_type) {
          obj.page_type = 'index'
        }
        const { data } = await service.info({
          ...obj,
        })
        this.pageData = data
        this.pageData.component.map(item => {
          item._id = guid()
        })
        this.info.title = data.title
        this.info.state_bar = data.state_bar
        this.loading = false
      } catch (e) {}
    },
    async getInfoNoComponent() {
      try {
        const { data } = await service.info({
          ...this.editorInfo,
        })
        this.pageData.is_publish = data.is_publish
        this.pageData.state_bar = data.state_bar
        this.pageData.title = data.title
        this.info.title = data.title
        this.info.state_bar = data.state_bar
      } catch (e) {}
    },
    change(_data) {
      this.detailData = _data
    },
    addItem(item) {
      this.$refs.content.activeId(item)
      this.$refs.content.data.push(item)
      this.$refs.content.add()
    },
    active(data) {
      this.$refs.content.activeId(data)
    },
    setOk() {
      this.$refs.setForm.validate().then(e => {
        if (!e) {
          return
        }
        this.info.title = this.modal.title
        this.info.state_bar = this.modal.state_bar
        this.visible.setting = false
        this.save()
      })
    },
    async save(publish) {
      try {
        const { data } = await service.save({
          ...this.editorInfo,
          title: this.info.title,
          state_bar: this.info.state_bar,
          is_publish: publish ? 1 : 0,
          component: JSON.stringify(this.$refs.content.data),
        })
        this.$message.success(publish ? '发布成功' : '保存成功')
        if (this.editorInfo.page_type === 'template' && !this.editorInfo.id) {
          this.$store.commit('EDITOR', {
            page_type: 'template',
            id: data.id,
          })
        }
        this.getInfoNoComponent()
      } catch (e) {}
    },
    setCancel() {
      this.visible.setting = false
    },
    headerChange(type) {
      switch (type) {
        case 'set':
          this.visible.setting = true
          this.modal.title = this.info.title
          this.modal.state_bar = this.info.state_bar
          break
      }
    },
    getContentData() {
      this.contentData = this.$refs.content ? _.cloneDeep(this.$refs.content.data) : []
    },
    beforeEnter(el) {
      el.style.transform = 'scale(1)'
      el.style.left = document.documentElement.clientWidth / 2 + 'px'
      el.style.top = document.documentElement.clientHeight / 2 + 'px'
    },
    // 进入中
    enter(el, done) {
      el.style.left = document.getElementById('car').getBoundingClientRect().left + 60 + 'px'
      el.style.top = 0 + 'px'
      el.style.transform = 'scale(0.5)'
      el.style.transition = 'left 1s cubic-bezier(1,0.3,1,.68), top 1s cubic-bezier(.1,0.1,0.5,.68), transform 1s'
      done()
    },
    // 进入后
    afterEnter() {
      this.$store.commit('CAR', false)
    },
  },
}
</script>

<style lang="less" module>
.layout {
  min-height: 100%;
}

.box {
  display: flex;
  flex-direction: row;
}

.set {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .tip {
    color: #5c5c5c;
    font-size: 12px;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.car {
  position: absolute;
  z-index: 99999;
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 100%;

  img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}
</style>
