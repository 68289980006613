<template>
  <div @click="onChoose()">
    <p v-if="data.title_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
    </p>
    <div
      v-if="data.swiper === 0"
      :class="$style.default"
      :style="{ height: height + 'px', padding: data.style === 0 ? '20px' : 0 }"
    >
      <div
        :class="$style.box"
        :style="{
          height: data.style === 0 ? height - 40 + 'px' : height + 'px',
          'border-radius': data.style === 0 ? '6px' : 0,
        }"
      >
        <div
          v-if="data.data && data.data.length && data.swiper_title_show"
          :class="$style.tip"
          :style="{ 'font-size': tipSize }"
        >
          <p class="ellipsis-width2">{{ data.data[0].title }}</p>
        </div>
        <img v-if="data.data && data.data.length" :src="data.data[0].data.cover" />
        <div v-else :class="$style.fake">
          <img :class="$style.img" :src="oss + '/manage/media/assets/icon.png'" />
        </div>
        <div
          v-if="data.data.length > 1 && data.mode === 0"
          :class="[$style.btn, data.style === 0 ? $style.docSmall : null]"
        >
          <span v-for="(item, index) in data.data" :key="index" :class="$style.doc" />
        </div>
        <div
          v-if="data.data.length > 1 && data.mode === 1"
          :class="[$style.btnMode, data.style === 0 ? $style.modeSmall : null]"
        >
          <span v-for="(item, index) in data.data" :key="index" :class="$style.docMode" />
        </div>
      </div>
    </div>
    <div v-else :class="$style.window" :style="{ height: height + 'px' }">
      <div :class="$style.box">
        <div
          :class="$style.center"
          :style="{
            height: height + 'px',
          }"
        >
          <div
            v-if="data.data && data.data.length && data.swiper_title_show"
            :class="$style.tip"
            :style="{ 'font-size': tipSize }"
          >
            <p class="ellipsis-width2">{{ data.data[0].title }}</p>
          </div>
          <img v-if="data.data && data.data.length" :src="data.data[0].data.cover" />
          <div v-else :class="$style.fake">
            <img :class="$style.img" :src="oss + '/manage/media/assets/icon.png'" />
          </div>
        </div>
        <div
          v-if="data.data.length > 1"
          :class="$style.right"
          :style="{
            width: 350 / (height / (height - 20)) + 'px',
            height: height - 20 + 'px',
            right: -(350 / (height / (height - 20))) + 10 + 'px',
          }"
        >
          <div v-if="data.swiper_title_show" :class="$style.tip" :style="{ 'font-size': tipSize }">
            <p class="ellipsis-width2">{{ data.data[0].title }}</p>
          </div>
          <img :src="data.data[1].data.cover" />
        </div>
        <div
          v-if="data.data.length > 2"
          :class="$style.left"
          :style="{
            width: 350 / (height / (height - 20)) + 'px',
            height: height - 20 + 'px',
            left: -(350 / (height / (height - 20))) + 10 + 'px',
          }"
        >
          <div v-if="data.swiper_title_show" :class="$style.tip" :style="{ 'font-size': tipSize }">
            <p class="ellipsis-width2">{{ data.data[0].title }}</p>
          </div>
          <img :src="data.data[1].data.cover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'swiper',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    height() {
      if (this.data.swiper === 0) {
        switch (this.data.size) {
          case 0:
            return 160
          case 1:
            return 240
          case 2:
            return 340
        }
      } else {
        switch (this.data.size) {
          case 0:
            return 120
          case 1:
            return 200
          case 2:
            return 300
        }
      }
      return 300
    },
    tipSize() {
      switch (this.data.size) {
        case 0:
          return '12px'
        case 1:
          return '13px'
        case 2:
          return '14px'
      }
      return '12px'
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.swiper {
  position: relative;
  height: 150px;
  text-align: center;
}

.head {
  margin: 0;
  padding: 20px 20px 14px 20px;

  .title {
    display: inline-block;
    width: 100%;
    color: #000;
    font-size: 18px;
  }
}

.default {
  .box {
    position: relative;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tip {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.2);

      p {
        margin-bottom: 0;
      }
    }

    .doc {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-right: 5px;
      line-height: 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    .doc:first-child {
      width: 12px;
      background: @primary-color;
    }

    .btn {
      position: absolute;
      bottom: 0%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    .btn-mode {
      position: absolute;
      top: 50%;
      right: 2%;
      width: 5px;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    .doc-mode {
      float: left;
      width: 5px;
      height: 5px;
      margin-top: 5px;
      line-height: 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    .doc-mode:first-child {
      height: 12px;
      background: @primary-color;
    }
  }
}

.window {
  .box {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .center {
    position: absolute;
    left: 20px;
    width: 350px;
    overflow: hidden;
    border-radius: 6px;
  }

  .left {
    position: absolute;
    top: 10px;
    overflow: hidden;
    border-radius: 6px;
  }

  .right {
    position: absolute;
    top: 10px;
    overflow: hidden;
    border-radius: 6px;
  }

  .tip {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);

    p {
      margin-bottom: 0;
    }
  }
}

.fake {
  width: 100%;
  height: 100%;
  background: #eee;

  .img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px !important;
    height: 50px !important;
    transform: translate(-50%, -50%);
  }
}
</style>
