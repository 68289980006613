<template>
  <div :class="$style.home">
    <bjInput
      v-model="param.title"
      v-bind="layout"
      rules="required"
      tooltips
      class="pr-40"
      placeholder="请输入组件标题"
      label="组件标题"
    >
      <BjSwitch slot="suffix" v-model="param.title_show" />
    </bjInput>
    <a-row>
      <a-col span="6" />
      <a-col span="18">
        <bjInput v-model="param.enter" rules="required" tooltips placeholder="请输入组件标题">
          <BjSwitch slot="suffix" v-model="param.enter_show" />
        </bjInput>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
