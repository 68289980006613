<template>
  <div :class="$style.content">
    <div :class="$style.box">
      <img
        v-if="pageData.state_bar && pageData.page_type !== 'template'"
        key="phoneHead"
        :class="$style.phoneHead"
        :src="oss + '/manage/media/assets/statusbar_w.png'"
      />
      <img
        v-if="!pageData.state_bar && pageData.page_type !== 'template'"
        key="phoneHead"
        :class="$style.phoneHead"
        :src="oss + '/manage/media/assets/statusbar_b.png'"
      />
      <div v-if="pageData.page_type === 'template'">
        <img
          key="phoneHead"
          :class="[$style.phoneHead, $style.back]"
          :src="oss + '/manage/media/assets/statusbar_b.png'"
        />
      </div>
      <draggable
        v-model="data"
        :class="$style.container"
        :style="mt"
        group="site"
        animation="300"
        :move="onMove"
        chosen-class="ghostchosen"
        ghost-class="ghostclass"
        filter=".forbid"
        @add="add"
      >
        <div v-if="pageData.page_type === 'template'" class="ellipsis" :class="$style.templateTitle">
          {{ pageData.title }}
        </div>
        <transition-group>
          <div
            v-for="(item, index) in data"
            :key="item._id"
            :class="[$style.ghost, id === item._id ? 'ghost-active' : null, item.position !== 'any' ? 'forbid' : null]"
          >
            <swiper v-if="item.type === 'swiper'" :data="item" @change="change" />
            <heads v-if="item.type === 'header'" :data="item" @change="change" />
            <contentList v-if="item.type === 'content_list'" :data="item" @edit="edit" @change="change" />
            <card v-if="item.type === 'poc_card'" :data="item" @change="change" />
            <relation v-if="item.type === 'poc_relation'" :data="item" @change="change" />
            <pocHeader v-if="item.type === 'poc_header'" :data="item" @change="change" />
            <navigation v-if="item.type === 'navigation'" :data="item" @change="change" />
            <notice v-if="item.type === 'notice'" :data="item" @change="change" />
            <news v-if="item.type === 'news'" :data="item" @change="change" />
            <venue v-if="item.type === 'venue'" :data="item" @change="change" />
            <activity v-if="item.type === 'activity'" :data="item" @change="change" />
            <div class="editoractive" />
            <!-- 选择框 -->
            <div v-if="id === item._id && anyShow(item, index)" :class="$style.sideBtn">
              <!-- 不是第一个且position 是any 且上面一个不是any -->
              <div
                v-if="index !== 0 && item.position === 'any' && otherTop(index)"
                :class="$style.btn"
                @click="onMoveUp(index)"
              >
                <!-- other 判断是不是上一个 下一个 不能动 -->
                <i class="ri-arrow-up-line" />
                <!-- 上 -->
              </div>
              <!-- 不是第最后个且position 是any -->
              <div
                v-if="index !== data.length - 1 && item.position === 'any' && otherBottom(index)"
                :class="$style.btn"
                @click="onMoveDown(index)"
              >
                <!-- 下 -->
                <i class="ri-arrow-down-line" />
              </div>
              <div v-if="!item.exist" :class="$style.btn" @click="onCopy(index)">
                <!-- 复制 -->
                <i class="ri-file-copy-line" />
              </div>
              <div v-if="!item.reject_delete" :class="$style.btn" @click="onDelete(index)">
                <!-- 删除 -->
                <i class="ri-delete-bin-7-line" />
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
      <img key="phoneFoot" :class="$style.phoneFoot" :src="oss + '/manage/media/assets/homebar.png'" alt="" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'

import { guid } from '@/utils'

import activity from './components/activity.vue'
import card from './components/card.vue'
import contentList from './components/contentList.vue'
import heads from './components/heads.vue'
import navigation from './components/navigation.vue'
import news from './components/news.vue'
import notice from './components/notice.vue'
import pocHeader from './components/pocHeader.vue'
import relation from './components/relation.vue'
import swiper from './components/swiper.vue'
import venue from './components/venue.vue'

export default {
  name: 'Content',
  components: {
    draggable,
    swiper,
    heads,
    contentList,
    card,
    relation,
    pocHeader,
    navigation,
    notice,
    news,
    venue,
    activity,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      id: null,
    }
  },
  computed: {
    mt() {
      return {
        'padding-top': this.pageData.page_type === 'template' ? '44px' : '0',
      }
    },
  },
  watch: {
    pageData: {
      handler(val) {
        if (val.page_type) {
          this.data = val.component
          if (this.data.length) {
            this.change(this.data[0])
          }
        }
      },
      immediate: true,
    },
    data: {
      handler() {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('refreshList')
      },
      immediate: true,
    },
  },
  methods: {
    onMoveUp(index) {
      this.data = _.cloneDeep(this.swap(this.data, index, index - 1))
      this.change(this.data[index - 1])
    },
    onMoveDown(index) {
      this.data = _.cloneDeep(this.swap(this.data, index, index + 1))
      this.change(this.data[index + 1])
    },
    onDelete(index) {
      this.data.splice(index, 1)
      this.data = _.cloneDeep(this.data)
      if (index !== 0) {
        // 删除了选择上一个
        this.id = this.data[index - 1]._id
        this.change(this.data[index - 1])
      }
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('refreshList')
    },
    onCopy(index) {
      // 复制修改id
      this.data.push(_.cloneDeep({ ...this.data[index], _id: guid() }))
    },
    swap(array, first, second) {
      let tmp = array[second]
      array[second] = array[first]
      array[first] = tmp
      return array
    },
    change(_data) {
      this.id = _data._id
      this.$emit('change', _data)
    },
    edit(_data) {
      this.data.map(item => {
        if (item._id === _data._id) {
          item.data = _data.data
        }
      })
      this.data = _.cloneDeep(this.data)
    },
    onMove(e, x) {
      if (x.clientX < 250) return false // 右往左
      if (e.draggedContext.element.position !== 'any' || e.relatedContext.element.position !== 'any') return false
      return true
    },
    add() {
      let tmp = []
      let last = null
      this.data.map(item => {
        if (item.position === 'first') {
          tmp.unshift(item)
        } else if (item.position === 'last') {
          last = item
        } else {
          tmp.push(item)
        }
      })
      if (last) {
        tmp.push(last)
      }
      this.data = _.cloneDeep(tmp)
    },
    // eslint-disable-next-line vue/no-unused-properties
    activeId(data) {
      setTimeout(() => {
        this.data.map(item => {
          if (item._id === data._id) {
            this.change(item)
          }
        })
      }, 100)
    },
    otherTop(index) {
      if (index === 0) return false
      if (this.data[index - 1].position !== 'any') {
        return false
      }
      return true
    },
    otherBottom(index) {
      if (index === this.data.length - 1) {
        return false
      }
      if (this.data[index + 1].position !== 'any') {
        return false
      }
      return true
    },
    // 如果全部按钮都没显示的情况 加按钮要维护
    anyShow(item, index) {
      return !(
        !(index !== 0 && item.position === 'any' && this.otherTop(index)) &&
        !(index !== this.data.length - 1 && item.position === 'any' && this.otherBottom(index)) &&
        item.exist &&
        item.reject_delete
      )
    },
  },
}
</script>

<style lang="less" module>
.content {
  position: relative;
  flex: 1;
  box-sizing: content-box;
  padding-top: 20px;
  overflow-y: auto;
  background-color: #fafafa;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .box {
    position: relative;
    width: 390px;
    min-height: 100%;
    margin: 0 auto 20px;
  }

  .container {
    position: relative;
    width: 390px;
    min-height: 100%;
    padding-bottom: 34px;
    font-size: 14px;
    background-color: #fafafa;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

    & > span {
      display: block;
      width: 100%;
      min-height: calc(100vh - 90px);
    }
  }
}

.ghost {
  position: relative;
  margin-bottom: 20px;
  cursor: grab;
}

.template-title {
  height: 44px;
  color: #000;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  background: #fff;
}

.back {
  background: #fff;
}

.phone-head {
  position: absolute;
  top: 0;
  z-index: 666;
  width: 390px;
  user-select: none;
}

.phone-foot {
  position: absolute;
  bottom: 0;
  z-index: 666;
  width: 390px;
  user-select: none;
}

.side-btn {
  position: absolute;
  top: 0;
  right: -50px;
  width: 40px;
  font-size: 18px;
  background: #fff;
  border: 1px solid #eee;

  .btn {
    height: 40px;
    color: #000;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }

  .btn:last-child {
    border-bottom: none;
  }

  .btn:hover {
    color: @primary-color;
    background: #eee;
  }
}
</style>

<style lang="less">
.editoractive {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  display: none;
  border: 2px solid @primary-color;
  pointer-events: none;
}

.ghostchosen {
  .editoractive {
    display: block;
    backdrop-filter: saturate(180%) blur(6px);
  }
}

.ghostclass {
  cursor: grabbing;
}

.ghostchosen:hover {
  cursor: grabbing;
}

.ghost-active {
  .editoractive {
    display: block;
  }
}

.cbox {
  width: 100%;
  height: 70px;
  color: @primary-color;
  font-size: 12px;
  text-align: center;
  border: 1px solid @primary-color;
  cursor: pointer;
  /* stylelint-disable-next-line no-descending-specificity */
  i {
    color: @primary-color;
    font-size: 24px;
  }
}
</style>
