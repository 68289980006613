<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        label-align="left"
        tooltips
        class="pr-40"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <a-row>
        <a-col span="6" />
        <a-col span="18">
          <BjInput
            v-model="param.enter"
            rules="required"
            label-align="left"
            class="pr-40"
            tooltips
            placeholder="请输入组件标题"
          >
            <BjSwitch slot="suffix" v-model="param.enter_show" />
          </BjInput>
        </a-col>
      </a-row>
      <BjValidationItem class="mt-20" rules="required" label-align="left" label="排序方式" v-bind="layout">
        <a-radio-group v-model="param.sort" @change="onChange">
          <a-radio :style="radioStyle" :value="0"> 用户位置与场馆距离（近-远） </a-radio>
          <a-radio :style="radioStyle" :value="1"> 场馆访问数量（多-少） </a-radio>
          <a-radio :style="radioStyle" :value="2"> 场馆添加时间（近-远） </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem class="mt-20" rules="required" label-align="left" label="数据来源" v-bind="layout">
        <a-radio-group v-model="param.source" @change="onChange">
          <a-radio :value="0"> 热门场馆 </a-radio>
          <a-radio :value="1"> 自定义数据 </a-radio>
        </a-radio-group>
        <div class="tip">热门场馆将显示访问量最高的场馆，内容最多显示10条。</div>
      </BjValidationItem>
      <p v-if="param.source === 1" :class="$style.title">
        <span class="required">*</span>
        选择场馆
      </p>
      <div v-if="param.source === 1" :class="$style.box">
        <div v-if="dataAddEffective" :class="$style.boxAdd" @click="onAdd()">
          <i class="ri-add-line" />
          选择场馆
        </div>
        <div v-for="(item, index) in param.data" :key="item.id" :class="$style.boxEdit">
          <div :class="$style.img">
            <img :src="item.cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <div class="ellipsis-width">{{ item.name }}</div>
            <div class="ellipsis2 tip" :title="item.summary">
              {{ item.summary }}
            </div>
          </div>
          <div v-if="param.source === 1" :class="$style.btn">
            <i class="ri-delete-bin-7-line" @click="onDelete(item, index)" />
          </div>
        </div>
      </div>
    </BjForm>
    <chooseVenue :visible.sync="visible.choose" :multiple="true" :select="param.data" @change="contentChange" />
  </div>
</template>

<script>
import chooseVenue from '@/components/chooseVenue.vue'

export default {
  name: 'venueSetting',
  components: {
    chooseVenue,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {
        data: [],
        hot: [],
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        choose: false,
      },
      radioStyle: {
        display: 'block',
        height: '24px',
        lineHeight: '24px',
      },
    }
  },
  computed: {
    dataAddEffective() {
      return this.param.data.length < 10
    },
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.data)
    },
    contentChange(data) {
      this.param.data = data
      this.onChange()
    },
    onAdd() {
      this.visible.choose = true
    },
    onDelete(item, index) {
      this.param.data.splice(index, 1)
      this.onChange()
    },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      padding: 10px;
      font-size: 12px;

      .grey {
        color: #5c5c5c;
      }
    }

    .btn {
      width: 25px;

      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  .title {
    color: #000;
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
