<template>
  <div :class="$style.notice">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
    </BjForm>
  </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    // onChange() {
    //   this.$emit('change', this.param)
    // },
  },
}
</script>

<style lang="less" module>
.notice {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
