<template>
  <a-dropdown
    v-model="visible"
    :class="$style.file"
    :trigger="['click']"
    :get-popup-container="
      triggerNode => {
        return triggerNode.parentNode.parentNode
      }
    "
    placement="bottomRight"
  >
    <span>
      <a-badge v-if="showBtn" :class="$style.badge" :count="uploadNum" :offset="uploadNum ? [12, -12] : [12, 12]">
        <slot>
          <!-- <i v-if="uploadNum" :class="$style.icon" class="ri-arrow-up-line" /> -->
          <a-progress
            v-if="uploadNum"
            :class="$style.progress"
            :width="20"
            :stroke-width="10"
            :show-info="false"
            type="circle"
            :percent="allPercent"
          />
          <i v-else :class="$style.icon" class="ri-upload-cloud-line" />
        </slot>
      </a-badge>
      <a-badge v-else :count="uploadNum" :offset="[-1, -1]" :dot="true">
        <slot>
          <i v-if="uploadNum" :class="[$style.icon, $style.num]" class="ri-arrow-up-line" />
          <i v-else :class="[$style.icon, $style.num]" class="ri-upload-cloud-line" />
        </slot>
      </a-badge>
    </span>
    <div slot="overlay" class="box-shadow" :class="$style.container">
      <p :class="$style.title">素材任务</p>
      <div :class="$style.scroll">
        <div v-for="(item, index) in uploadFile" :key="index" :class="$style.item">
          <div :class="$style.img">
            <i v-if="item.type === 'video'" class="ri-play-circle-line" />
            <i v-if="item.type === 'image'" class="ri-image-line" />
            <i v-if="item.type === 'audio'" class="ri-music-line" />
          </div>
          <div :class="$style.name">
            <p :title="item.name" class="ellipsis-width">{{ item.name }}</p>
            <p :class="$style.size">{{ item.size | fileSize }}</p>
          </div>
          <div :class="$style.state">
            <div :class="$style.default">
              <i
                v-if="item.progress === 100 && item.exception === true"
                :class="$style.success"
                class="ri-checkbox-circle-line"
              />
              <a-progress
                v-if="item.progress !== 100 && item.exception === true"
                type="circle"
                :width="20"
                :stroke-width="8"
                :show-info="false"
                :percent="item.progress"
              />
              <i v-if="item.exception === false" :class="$style.warning" class="ri-close-circle-line" />
              <i v-if="item.exception === 'warning'" :class="$style.fail" class="ri-spam-2-line" />
            </div>
            <div :class="$style.agin">
              <span v-if="item.exception === 'warning'" class="tag mr-10" @click="resumeUpload(item)"> 重试 </span>
              <i
                v-if="!(item.progress !== 100 && item.exception === true)"
                :class="$style.delete"
                class="ri-delete-bin-7-line"
                @click="onDelete(item)"
              />
              <a-tooltip v-else>
                <template slot="title">上传进度：{{ item.progress }}% </template>
                <a-progress
                  v-if="item.progress !== 100 && item.exception === true"
                  type="circle"
                  :width="20"
                  :stroke-width="8"
                  :show-info="false"
                  :percent="item.progress"
                />
              </a-tooltip>
            </div>
          </div>
        </div>
        <div v-if="!uploadFile.length" :class="$style.noData">
          <img :src="oss + '/manage/media/state/no_data.png'" alt="" />
          <p>暂时没有任务</p>
        </div>
      </div>
      <div :class="$style.foot">
        <a-row>
          <a-col
            span="12"
            :class="[$style.br, uploadNum !== uploadFile.length ? $style.cursor : $style.disable]"
            @click="clear()"
          >
            <i
              :class="[uploadNum !== uploadFile.length ? $style.cursor : $style.disable]"
              class="ri-check-double-line"
            />
            <span>清除已完成</span>
          </a-col>
          <a-col span="12">
            <i class="ri-image-line cursor" @click="to()" />
            <span class="cursor" @click="to()">进入素材中心</span>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BjFile',
  filters: {
    fileSize(bytes) {
      if (bytes === 0) return '0 B'
      let k = 1024
      let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      let i = Math.floor(Math.log(bytes) / Math.log(k))
      return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i]
    },
  },
  props: {
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['uploadNum']),
    visible: {
      get() {
        return this.$store.state.uploadVisible
      },
      set(val) {
        this.$store.commit('UPLOAD_VISIBLE', val)
      },
    },
    uploadFile: {
      get() {
        return this.$store.state.uploadFile
      },
      set(val) {
        this.$store.commit('UPlOADFILE', val)
      },
    },
    allPercent() {
      let tmp = 0
      this.uploadFile.forEach(item => {
        tmp += item.progress
      })
      return tmp / this.uploadFile.length
    },
  },
  methods: {
    ...mapActions(['resumeUpload']),
    to() {
      if (this.$route.name === 'contentMaterial') {
        this.visible = false
        return
      }
      this.$router.push({
        name: 'contentMaterial',
      })
      this.visible = false
    },
    clear() {
      if (!this.uploadNum === this.uploadFile.length) return
      let tmp = []
      for (let index = 0; index < this.uploadFile.length; index++) {
        if (this.uploadFile[index].progress !== 100) {
          tmp.push(this.uploadFile[index])
        }
      }
      this.uploadFile = tmp
    },
    onDelete(item) {
      let tmp = []
      for (let index = 0; index < this.uploadFile.length; index++) {
        if (this.uploadFile[index].id !== item.id) {
          tmp.push(this.uploadFile[index])
        }
      }
      this.uploadFile = tmp
      if (!(item.exception && item.progress === 100)) {
        // 只有没完成的需要数量 -1
        this.$store.commit('UPLOADNUM', this.uploadNum - 1)
      }
    },
  },
}
</script>

<style lang="less" module>
.container {
  width: 350px;
  height: 400px;
  color: #000;
  background-color: #fff;
  border-radius: 6px;
}

.scroll {
  height: 294px;
  overflow-x: hidden;
  overflow-y: auto;
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-top: 1px solid #eee;

  span {
    font-size: 12px;
  }

  i {
    margin-right: 6px;
    color: #000;
    font-size: 18px;
    vertical-align: -4px;
  }

  .br {
    border-right: 1px solid #eee;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 78px;
  padding: 14px;

  .img {
    width: 50px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: #fafafa;
  }

  .name {
    flex: 1;
    padding: 10px 20px 10px 10px;
    text-align: left;

    p {
      margin: 0;
    }

    .size {
      margin-top: 5px;
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .state {
    width: 80px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}

.agin {
  display: none;
  text-align: right;
}

.default {
  text-align: right;
}

.item:hover {
  background: #fafafa;

  .agin {
    display: block;
  }

  .default {
    display: none;
  }
}

.title {
  margin: 0;
  padding: 14px;
  font-size: 16px;
}

.icon {
  width: 48px;
  font-size: 22px;
  line-height: 60px;
}

.num {
  line-height: 0;
}

.success {
  color: @primary-color;
  font-size: 22px;
}

.fail {
  color: #ff9502;
  font-size: 22px;
}

.delete {
  color: @error-color;
  font-size: 22px;
  vertical-align: -5px;
}

.warning {
  color: @error-color;
  font-size: 22px;
}

.disable {
  color: #bbb !important;
  cursor: not-allowed;
}

.cursor {
  cursor: pointer;
}

.no-data {
  padding-top: 40px;
  color: #5c5c5c;
  text-align: center;

  img {
    width: 170px;
  }
}

.badge {
  :global {
    .ant-badge-count {
      transform: scale(0.8);
    }

    .ant-badge {
      top: -4px;
    }
  }
}

.progress {
  position: relative;
  top: -4px;
}
</style>
