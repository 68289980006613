<template>
  <div :class="$style.relation" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" class="ellipsis" :class="$style.more">
        <span>{{ data.enter }} </span>
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div :class="$style.box">
      <div v-for="(item, index) in list" :key="index" :class="$style.item">
        <img v-if="item.default !== 'default'" :src="item.rel_poc.cover.cover" alt="" />
        <div v-else :class="$style.block" />
        <p :class="$style.title" class="ellipsis-width">{{ item.rel_poc.name }}</p>
        <p :class="$style.tip" class="ellipsis-width">{{ item.relation_name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { relationService } from '@/service'

const service = new relationService()

export default {
  name: 'relation',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
      default: [
        {
          rel_poc: {
            name: '文化点',
          },
          relation_name: '关系描述',
          default: 'default',
        },
        {
          rel_poc: {
            name: '文化点',
          },
          relation_name: '关系描述',
          default: 'default',
        },
        {
          rel_poc: {
            name: '文化点',
          },
          relation_name: '关系描述',
          default: 'default',
        },
        {
          rel_poc: {
            name: '文化点',
          },
          relation_name: '关系描述',
          default: 'default',
        },
      ],
    }
  },
  computed: {
    ...mapState(['editor']),
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.detail({
          poc_id: this.editor.poc_id,
        })
        if (data.length) {
          this.list = data
        } else {
          this.list = this.default
        }
      } catch (e) {}
    },
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.relation {
  min-height: 120px;

  .head {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 14px 20px;

    .title {
      flex: 1;
      color: #000;
      font-size: 18px;
    }

    .more {
      max-width: 150px;
      padding-right: 15px;
      color: #5c5c5c;
      font-size: 12px;
      line-height: 25px;
      text-align: right;

      i {
        position: absolute;
        right: 20px;
        font-size: 17px;
        vertical-align: -4px;
      }
    }
  }

  .box {
    display: flex;
    overflow: hidden;

    .item {
      width: 60px;
      height: 120px;
      margin: 0 20px;
      text-align: center;

      .block {
        display: inline-block;
        width: 60px;
        height: 60px;
        background-color: #eee;
        border-radius: 100%;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }

      p {
        margin-bottom: 0;
      }
    }

    .title {
      margin-top: 10px;
      color: #000;
      font-size: 14px;
    }

    .tip {
      margin-top: 2px;
      color: #bbb;
      font-size: 12px;
    }
  }
}
</style>
