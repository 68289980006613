<template>
  <div :class="$style.side">
    <div :class="$style.left">
      <div
        v-for="(item, index) in data"
        :key="index"
        :class="[$style.box, item.type === active ? $style.boxActive : null]"
        @click="onActive(item, index)"
      >
        <i :class="item.icon" />
        <p>{{ item.label }}</p>
      </div>
    </div>
    <div :class="$style.right">
      <draggable
        v-if="!loading"
        v-model="children"
        animation="300"
        :move="onMove"
        :group="{ name: 'site', pull: 'clone', put: true }"
        :clone="clone"
        filter=".forbid"
      >
        <transition-group>
          <div
            v-for="item in children"
            :key="item.type"
            class="cbox"
            :class="[$style.cbox, getDisabled(item) ? $style.disabled : null, getDisabled(item) ? 'forbid' : null]"
            @click="addItem(item)"
          >
            <a-tooltip :title="getDisabled(item) ? '页面仅可添加一个此组件' : ''">
              <i :class="item.icon" />
              <p>{{ item.label }}</p>
            </a-tooltip>
          </div>
        </transition-group>
      </draggable>
      <a-spin v-else />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import { editorService } from '@/service'
import { guid } from '@/utils'

const service = new editorService()

export default {
  name: 'Sidebar',
  components: {
    draggable,
  },
  props: {
    editorInfo: {
      type: Object,
      default: () => ({}),
    },
    contentData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [
        // 位置定义 any first last
        // exist 一个页面只能一个 0 1
        // reject_delete 0 1  1不能删除
        // 左边不显示 visible
        // 不要叫 info 后台用掉了
        {
          type: 'base',
          icon: 'ri-layout-bottom-2-line',
          label: '基础',
          children: [
            {
              type: 'header',
              icon: 'ri-layout-top-line',
              label: '首页头部',
              position: 'first',
              exist: 1,
              reject_delete: 1, // 是否能删除
              visible: false,
              style: 0, // 样式
              style_type: 0, // 背景类型
              background: {
                // 背景样式
                id: null,
                cover: null,
                type: 'image', // 类型
                path: '',
              },
              background_color: '#000000', // 背景颜色
              tip: '探索更多文化...', // 搜索文案
              back_tip: '', // 背景文案
              data: [], // 轮播图
              /**
               * data: [{title, link , data}]
               * link: { type, params } // 跳转 详情文档
               * data: {cover, type, id} // 图片数据
               */
              nav: [],
              /**
               * data: [{title, link , data}]
               * link: { type, params } // 跳转 详情文档
               * data: {cover, type, id} // 图片数据
               */
            },
            {
              type: 'swiper',
              icon: 'ri-layout-column-line',
              label: '轮播',
              title: '轮播', // 标题
              title_show: 0, // 是否显示标题
              swiper: 0, // 显示样式
              size: 0, // 尺寸
              swiper_title_show: 1, // 标题显示
              style: 0, // 显示样式
              mode: 0, // 轮播方式
              position: 'any',
              data: [],
              /**
               * data: [{title, link , data}]
               * link: { type, params } // 跳转 详情文档
               * data: {cover, type, id} // 图片数据
               */
            },
            {
              type: 'navigation',
              icon: 'ri-apps-2-line',
              label: '导航',
              title: '导航', // 标题
              title_show: 0, // 是否显示标题
              exist: 0, // 存在
              position: 'any', // 位置
              style: 0,
              row_num: 0, // 一行几个 0 1 2
              data: [], // 数据
              /**
               * data: [{title, link , data}]
               * link: { type, params } // 跳转 详情文档
               * data: {cover, type, id} // 图片数据
               */
            },
          ],
        },
        {
          type: 'source',
          icon: 'ri-focus-line',
          label: '资源',
          children: [
            {
              type: 'poc_card',
              icon: 'ri-notification-badge-line',
              label: '文化卡片',
              title: '文化卡片', // 标题
              title_show: 1, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 1, // 是否显示入口
              style: 0, // 样式
              source: 0, // 来源
              hot_poc: [], // 热门文化点
              position: 'any',
              data: [], // 详情见文化点数据
            },
            {
              type: 'poc_relation',
              icon: 'ri-stackshare-line',
              label: '关系图谱',
              title: '关系图谱', // 标题
              title_show: 1, // 是否显示标题
              exist: 1,
              enter: '更多', // 入口文案
              enter_show: 0, // 是否显示入口
              position: 'any',
            },
            {
              type: 'poc_header',
              icon: 'ri-stackshare-line',
              label: '主页头部',
              position: 'first',
              visible: false,
              style: 0, // 样式
              info: {}, // 显示数据
            },
          ],
        },
        {
          type: 'content',
          icon: 'ri-bill-line',
          label: '内容',
          children: [
            {
              type: 'content_list',
              icon: 'ri-list-check-2',
              label: '内容列表',
              title: '内容列表', // 标题
              title_show: 1, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 1, // 是否显示入口
              exist: 1, // 存在
              sort: 0, // 排序
              load_mode: 0, //加载方式
              source: 0, // 来源
              position: 'any',
              data: [], // 详情见内容数据
            },
          ],
        },
        {
          type: 'app',
          icon: 'ri-apps-2-line',
          label: '应用',
          children: [
            {
              type: 'notice',
              icon: 'ri-notification-2-line',
              label: '公告信息',
              title: '公告', // 标题
              title_show: 0, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 0, // 是否显示入口
              exist: 1, // 存在
              position: 'any', // 位置
              data: [], // 数据
            },
            {
              type: 'news',
              icon: 'ri-file-list-3-line',
              label: '新闻资讯',
              title: '新闻资讯', // 标题
              title_show: 1, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 1, // 是否显示入口
              exist: 1, // 存在
              position: 'any', // 位置
              data: [], // 数据
            },
            {
              type: 'activity',
              icon: 'ri-football-line',
              label: '文化活动',
              title: '文化活动', // 标题
              title_show: 1, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 1, // 是否显示入口
              exist: 1, // 存在
              position: 'any', // 位置
              data: {
                main: {},
                secondary: [],
              }, // 数据
            },
            {
              type: 'venue',
              icon: 'ri-ancient-gate-line',
              label: '文化场馆',
              title: '文化场馆', // 标题
              title_show: 1, // 是否显示标题
              enter: '更多', // 入口文案
              enter_show: 1, // 是否显示入口
              exist: 1, // 存在
              position: 'any', // 位置
              sort: 0,
              source: 0,
              hot: [],
              data: [], // 数据
            },
          ],
        },
      ],
      children: [],
      active: 'base',
      showData: {},
      loading: false,
    }
  },
  async created() {
    const { data } = await service.getShow()
    this.showData = data
    this.onActive(this.data[0], 0) // 默认选择第一个分类的
    this.$nextTick(() => {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('getContentData')
    })
    this.getDefaultData()
  },
  methods: {
    getDefaultData() {
      // 获取所有从左边拖过去的组件的默认数据 在拖动前就请求
      this.getHotPoc()
      this.getNotice()
      this.getNews()
      this.getVenue()
    },
    async getHotPoc() {
      // 获取热门文化点数据
      const { data } = await service.hotPoc()
      this.data.map(item => {
        if (item.type === 'source') {
          item.children.map(child => {
            if (child.type === 'poc_card') {
              child.hot_poc = data
            }
          })
        }
      })
    },
    async getVenue() {
      const { data } = await service.getVenue()
      this.data.map(item => {
        if (item.type === 'app') {
          item.children.map(child => {
            if (child.type === 'venue') {
              child.hot = data
            }
          })
        }
      })
    },
    async getNotice() {
      const { data } = await service.getNotice({
        page: 1,
        page_size: 5,
        status: 1,
      })
      this.data.map(item => {
        if (item.type === 'app') {
          item.children.map(child => {
            if (child.type === 'notice') {
              child.data = data.record
            }
          })
        }
      })
    },
    async getNews() {
      const { data } = await service.getNews()
      this.data.map(item => {
        if (item.type === 'app') {
          item.children.map(child => {
            if (child.type === 'news') {
              child.data = data
            }
          })
        }
      })
    },
    clone(item) {
      let tmp = { ...item, _id: guid() }
      this.$emit('active', tmp)
      return tmp
    },
    onMove(e, x) {
      if (x.clientX < 250) return false // 右往左
      return true
    },
    addItem(item) {
      if (item.exist) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('getContentData') // 获取上次的
        let typeArr = this.contentData.map(item => item.type)
        if (typeArr.includes(item.type)) {
          return
        }
      }
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('addItem', { ...item, _id: guid() })
    },
    onActive(item, index) {
      this.loading = true
      setTimeout(() => {
        this.children = this.data[index].children.filter(item =>
          this.showData[this.editorInfo.page_type].includes(item.type)
        )
        // this.children = this.data[index].children // 取消注释显示所有组件  开发用
        this.active = item.type
        this.loading = false
      }, 200)
      // 判断哈是否显示这个组件
    },
    getDisabled(item) {
      if (item.exist) {
        let typeArr = this.contentData.map(item => item.type)
        if (typeArr.includes(item.type)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="less" module>
.side {
  display: flex;
  width: 230px;

  p {
    margin: 0;
    margin-top: -2px;
  }

  .left {
    width: 60px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  }

  .right {
    position: relative;
    width: 170px;
    border-right: 1px solid #eee;

    :global {
      .ant-spin {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .box {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    color: #000;
    font-size: 12px;
    text-align: center;
    cursor: pointer;

    i {
      color: #000;
      font-size: 24px;
    }
  }

  .box-active {
    color: @primary-color;
    background: #fafafa;

    i {
      color: @primary-color;
    }
  }

  .box:hover {
    color: @primary-color;
    background: #fafafa;

    i {
      color: @primary-color;
    }
  }

  .cbox {
    display: inline-block;
    width: 70px;
    height: 70px;
    margin: 7px;
    color: #000;
    font-size: 12px;
    text-align: center;
    border: none;
    cursor: pointer;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      color: #000;
      font-size: 24px;
    }
  }

  .cbox:hover {
    color: @primary-color;
    background: #fafafa;

    i {
      color: @primary-color;
    }
  }

  .disabled {
    color: #bbb !important;
    background: #fafafa !important;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      color: #bbb !important;
    }
  }

  .disabled:hover {
    color: #bbb !important;
    background: #fafafa !important;

    i {
      color: #bbb !important;
    }
  }
}
</style>
