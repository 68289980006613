<template>
  <div :class="$style.detail">
    <div v-if="data.label" :class="$style.title">编辑 「{{ data.label }}」</div>
    <div :class="$style.space">
      <swiper v-if="data.type === 'swiper'" :data="data" @change="onChange" />
      <heads v-if="data.type === 'header'" :data="data" @change="onChange" />
      <contentList v-if="data.type === 'content_list'" :data="data" @change="onChange" />
      <card v-if="data.type === 'poc_card'" :data="data" @change="onChange" />
      <relation v-if="data.type === 'poc_relation'" :data="data" @change="onChange" />
      <pocHeader v-if="data.type === 'poc_header'" :data="data" @change="onChange" />
      <navigation v-if="data.type === 'navigation'" :data="data" @change="onChange" />
      <notice v-if="data.type === 'notice'" :data="data" @change="onChange" />
      <news v-if="data.type === 'news'" :data="data" @change="onChange" />
      <venue v-if="data.type === 'venue'" :data="data" @change="onChange" />
      <activity v-if="data.type === 'activity'" :data="data" @change="onChange" />
    </div>
  </div>
</template>

<script>
import activity from './setting/activity.vue'
import card from './setting/card.vue'
import contentList from './setting/contentList.vue'
import heads from './setting/heads.vue'
import navigation from './setting/navigation.vue'
import news from './setting/news.vue'
import notice from './setting/notice.vue'
import pocHeader from './setting/pocHeader.vue'
import relation from './setting/relation.vue'
import swiper from './setting/swiper.vue'
import venue from './setting/venue.vue'

export default {
  name: 'Detail',
  components: {
    swiper,
    heads,
    contentList,
    card,
    relation,
    pocHeader,
    navigation,
    notice,
    news,
    venue,
    activity,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onChange(_data) {
      this.$emit('change', _data)
    },
  },
}
</script>

<style lang="less" module>
.detail {
  width: 360px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .title {
    height: 48px;
    padding: 0 14px;
    color: #000;
    font-size: 14px;
    line-height: 48px;
    border-bottom: 1px solid #eee;
  }

  .space {
    padding: 14px;
  }
}
</style>
