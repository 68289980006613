<template>
  <div :class="$style.card" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" class="ellipsis" :class="$style.more">
        <span>{{ data.enter }} </span>
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div v-if="data.source === 1">
      <div v-if="data.style === 0 && data.data.length" :class="$style.one">
        <div :class="$style.box">
          <img :src="data.data[0].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.data[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.data[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.data[0].summary }}</p>
          </div>
          <div v-if="data.data.length > 1" :class="[$style.fake, $style.box]">
            <img :src="data.data[1].cover.cover" alt="" />
            <div :class="$style.tag">{{ data.data[1].group_name }}</div>
            <div :class="$style.text">
              <p class="ellipsis-width">{{ data.data[1].name }}</p>
              <p class="ellipsis-width">{{ data.data[1].summary }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.style === 1 && data.data.length" :class="$style.two">
        <div :class="$style.box">
          <img :src="data.data[0].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.data[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.data[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.data[0].summary }}</p>
          </div>
        </div>
        <div v-if="data.data.length > 1" :class="$style.box">
          <img :src="data.data[1].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.data[1].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.data[1].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.data[1].summary }}</p>
          </div>
        </div>
        <div v-if="data.data.length > 2" :class="$style.box">
          <img :src="data.data[2].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.data[2].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.data[2].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.data[2].summary }}</p>
          </div>
        </div>
      </div>
      <div v-if="data.style === 2 && data.data.length" :class="$style.three">
        <div :class="$style.box">
          <img :src="data.data[0].cover.cover" alt="" />
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.data[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width2">{{ data.data[0].summary }}</p>
          </div>
          <div v-if="data.data.length > 1" :class="$style.fake" />
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="data.style === 0 && data.hot_poc.length" :class="$style.one">
        <div :class="$style.box">
          <img :src="data.hot_poc[0].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.hot_poc[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.hot_poc[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.hot_poc[0].summary }}</p>
          </div>
          <div v-if="data.hot_poc.length > 1" :class="[$style.fake, $style.box]">
            <img :src="data.hot_poc[1].cover.cover" alt="" />
            <div :class="$style.tag">{{ data.hot_poc[1].group_name }}</div>
            <div :class="$style.text">
              <p class="ellipsis-width">{{ data.hot_poc[1].name }}</p>
              <p class="ellipsis-width">{{ data.hot_poc[1].summary }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.style === 1 && data.hot_poc.length" :class="$style.two">
        <div :class="$style.box">
          <img :src="data.hot_poc[0].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.hot_poc[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.hot_poc[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.hot_poc[0].summary }}</p>
          </div>
        </div>
        <div v-if="data.hot_poc.length > 1" :class="$style.box">
          <img :src="data.hot_poc[1].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.hot_poc[1].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.hot_poc[1].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.hot_poc[1].summary }}</p>
          </div>
        </div>
        <div v-if="data.hot_poc.length > 2" :class="$style.box">
          <img :src="data.hot_poc[2].cover.cover" alt="" />
          <div :class="$style.tag">{{ data.hot_poc[2].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.hot_poc[2].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ data.hot_poc[2].summary }}</p>
          </div>
        </div>
      </div>
      <div v-if="data.style === 2 && data.hot_poc.length" :class="$style.three">
        <div :class="$style.box">
          <img :src="data.hot_poc[0].cover.cover" alt="" />
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ data.hot_poc[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width2">{{ data.hot_poc[0].summary }}</p>
          </div>
          <div v-if="data.hot_poc.length > 1" :class="$style.fake" />
        </div>
      </div>
    </div>
    <div v-if="(data.source === 1 && !data.data.length) || (data.source === 0 && !data.hot_poc.length)">
      <div v-if="data.style === 0" :class="$style.one">
        <div :class="$style.box">
          <div :class="$style.nodata">
            <img :src="fakeData[0].cover.cover" alt="" />
          </div>
          <div :class="$style.tag">{{ fakeData[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ fakeData[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ fakeData[0].summary }}</p>
          </div>
          <div v-if="fakeData.length > 1" :class="[$style.fake, $style.box]">
            <div :class="$style.nodata">
              <img :src="fakeData[0].cover.cover" alt="" />
            </div>
            <div :class="$style.tag">{{ fakeData[1].group_name }}</div>
            <div :class="$style.text">
              <p class="ellipsis-width">{{ fakeData[1].name }}</p>
              <p class="ellipsis-width">{{ fakeData[1].summary }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.style === 1 && fakeData.length" :class="$style.two">
        <div :class="$style.box">
          <div :class="$style.nodata2">
            <img :src="fakeData[0].cover.cover" alt="" />
          </div>
          <div :class="$style.tag">{{ fakeData[0].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ fakeData[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ fakeData[0].summary }}</p>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.nodata2">
            <img :src="fakeData[0].cover.cover" alt="" />
          </div>
          <div :class="$style.tag">{{ fakeData[1].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ fakeData[1].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ fakeData[1].summary }}</p>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.nodata2">
            <img :src="fakeData[0].cover.cover" alt="" />
          </div>
          <div :class="$style.tag">{{ fakeData[2].group_name }}</div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ fakeData[2].name }}</p>
            <p :class="$style.tip" class="ellipsis-width">{{ fakeData[2].summary }}</p>
          </div>
        </div>
      </div>
      <div v-if="data.style === 2" :class="$style.three">
        <div :class="$style.box">
          <div :class="$style.nodata3">
            <img :src="fakeData[0].cover.cover" alt="" />
          </div>
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width">{{ fakeData[0].name }}</p>
            <p :class="$style.tip" class="ellipsis-width2">{{ fakeData[0].summary }}</p>
          </div>
          <div :class="$style.fake" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fakeData: [
        {
          cloud_id: '1',
          cover: {
            cover: this.oss + '/manage/media/assets/icon.png',
          },
          group_name: '分组名称',
          id: '6541582b304145f787360b943ab43209',
          name: '文化点名称',
          summary: '文化点摘要描述信息',
          synonym: '',
        },
        {
          cloud_id: '1',
          cover: {
            cover: this.oss + '/manage/media/assets/icon.png',
          },
          group_name: '分组名称',
          id: '6541582b304145f787360b943ab43209',
          name: '文化点名称',
          summary: '文化点摘要描述信息',
          synonym: '',
        },
        {
          cloud_id: '1',
          cover: {
            cover: this.oss + '/manage/media/assets/icon.png',
          },
          group_name: '分组名称',
          id: '6541582b304145f787360b943ab43209',
          name: '文化点名称',
          summary: '文化点摘要描述信息',
          synonym: '',
        },
      ],
    }
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.card {
  min-height: 120px;

  .head {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 14px 20px;

    .title {
      flex: 1;
      color: #000;
      font-size: 18px;
    }

    .more {
      max-width: 150px;
      padding-right: 15px;
      color: #5c5c5c;
      font-size: 12px;
      line-height: 25px;
      text-align: right;

      i {
        position: absolute;
        right: 20px;
        font-size: 17px;
        vertical-align: -4px;
      }
    }
  }

  .one {
    overflow: hidden;

    .box {
      position: relative;
      width: 350px;
      height: 226px;
      margin: 0 auto;
      background: #fff;
      border-radius: 6px;

      img {
        border-radius: 6px;
      }
    }

    .tag {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      padding: 0 5px;
      color: #000;
      font-size: 12px;
      line-height: 17px;
      background-color: rgba(255, 210, 135, 100);
      border-radius: 6px 0 6px 0;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 350px;
      height: 160px;
      object-fit: cover;
    }

    .text {
      flex: 1;
      padding: 5px 5px 5px 15px;

      p {
        margin: 0;
        line-height: 25px;
      }

      .title {
        color: #000;
        font-size: 16px;
      }

      .tip {
        color: #bbb;
        font-size: 12px;
      }
    }

    .fake {
      position: absolute;
      top: 0;
      right: -360px;
    }
  }

  .two {
    display: flex;
    padding-left: 20px;
    overflow: hidden;

    .box {
      position: relative;
      width: 170px;
      height: 184px;
      margin-right: 10px;
      background: #fff;
      border-radius: 6px;

      img {
        border-radius: 6px;
      }
    }

    .tag {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      padding: 0 5px;
      color: #000;
      font-size: 12px;
      line-height: 17px;
      background-color: rgba(255, 210, 135, 100);
      border-radius: 6px 0 6px 0;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 170px;
      height: 120px;
      object-fit: cover;
    }

    .text {
      flex: 1;
      padding: 5px 5px 5px 15px;

      p {
        margin: 0;
        line-height: 25px;
      }

      .title {
        color: #000;
        font-size: 16px;
      }

      .tip {
        margin-top: 10px;
        color: #bbb;
        font-size: 12px;
      }
    }

    .fake {
      position: absolute;
      top: 0;
      right: -360px;
    }
  }

  .three {
    overflow: hidden;

    .box {
      position: relative;
      display: flex;
      width: 350px;
      height: 108px;
      margin: 0 auto;
      padding: 15px;
      background: #fff;
      border-radius: 6px;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }

    .text {
      flex: 1;
      padding: 5px 5px 5px 15px;

      p {
        margin: 0;
      }

      .title {
        color: #000;
        font-size: 16px;
      }

      .tip {
        margin-top: 10px;
        color: #bbb;
        font-size: 12px;
      }
    }

    .fake {
      position: absolute;
      top: 0;
      right: -360px;
      width: 350px;
      height: 108px;
      background: #fff;
      border-radius: 6px;
    }
  }
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 160px;
  background: #eee !important;

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.nodata2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 120px;
  background: #eee !important;

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.nodata3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #eee !important;

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: 30px !important;
    height: 30px !important;
  }
}
</style>
