<template>
  <div :class="$style.notice" @click="onChoose()">
    <p v-if="data.title_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
    </p>
    <div :class="$style.box">
      <img :class="$style.img" :src="oss + '/front/app/notice/speaker.png'" alt="" />
      <p v-if="data.data.length" :class="$style.text" class="ellipsis-width">
        {{ data.data[0].title }}
      </p>
      <p v-else :class="$style.text" class="ellipsis-width">暂时没有公告数据哦 O(∩_∩)O</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.notice {
  padding: 20px;

  .head {
    margin: 0;
    padding-bottom: 14px;

    .title {
      display: inline-block;
      width: 100%;
      color: #000;
      font-size: 18px;
    }
  }

  .box {
    display: flex;
    align-items: center;
    width: 350px;
    height: 44px;
    padding: 0 10px;
    line-height: 44px;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);

    .img {
      width: 24px;
      height: 24px;
    }

    .text {
      flex: 1;
      margin-bottom: 0;
      margin-left: 10px;
      color: #000;
      font-size: 13px;
    }
  }
}
</style>
