<template>
  <div :class="$style.head">
    <BjForm ref="form">
      <BjValidationItem rules="required" label-align="left" label="顶部样式" v-bind="layout">
        <a-radio-group v-model="param.style">
          <a-radio :value="0"> 样式一 </a-radio>
          <a-radio :value="1"> 样式二 </a-radio>
        </a-radio-group>
      </BjValidationItem>
    </BjForm>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" module>
// .head {
//
// }
</style>
