<template>
  <div :class="$style.news">
    <BjForm ref="form">
      <BjInput
        v-model="param.title"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入组件标题"
        label="组件标题"
      >
        <BjSwitch slot="suffix" v-model="param.title_show" />
      </BjInput>
      <a-row>
        <a-col span="6" />
        <a-col span="18">
          <bjInput v-model="param.enter" placeholder="请输入组件标题">
            <BjSwitch slot="suffix" v-model="param.enter_show" />
          </bjInput>
        </a-col>
      </a-row>
    </BjForm>
  </div>
</template>

<script>
export default {
  name: 'news',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
}
</script>

<style lang="less" module>
.news {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
