<template>
  <div :class="$style.head">
    <BjForm ref="form">
      <BjValidationItem rules="required" label-align="left" label="顶部样式" v-bind="layout">
        <a-radio-group v-model="param.style" @change="onChange">
          <a-radio :value="0"> 样式一 </a-radio>
          <a-radio :value="1"> 样式二 </a-radio>
          <a-radio :value="2"> 样式三 </a-radio>
          <a-radio :value="3"> 样式四 </a-radio>
          <a-radio :value="4"> 样式五 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem v-if="param.style !== 2" rules="required" label-align="left" label="背景类型" v-bind="layout">
        <a-radio-group v-model="param.style_type" @change="onChange">
          <a-radio :value="0"> 图片或视频 </a-radio>
          <a-radio :value="1"> 背景颜色 </a-radio>
        </a-radio-group>
      </BjValidationItem>
      <BjValidationItem
        v-if="param.style_type === 0 && param.style !== 2"
        rules="required"
        label-align="left"
        label="背景素材"
        v-bind="layout"
      >
        <selectMaterial :data="param.background" @change="backChange" />
        <div class="tip mt-10">背景素材支持图片和视频，图片大小建议不超过2M；视频大小建议不超过20M。</div>
      </BjValidationItem>
      <BjValidationItem
        v-if="param.style_type === 1 && param.style !== 2"
        rules="required"
        label-align="left"
        label="背景颜色"
        v-bind="layout"
      >
        <div :class="$style.color">
          <div
            v-click-outside="pickOut"
            :style="{ 'background-color': color.hex8 }"
            :class="$style.colorBox"
            class="out"
            @click.self="onChooseColor()"
          >
            <i class="ri-arrow-down-s-line" @click.self="onChooseColor()" />
            <div :class="$style.pick">
              <chrome-picker v-if="visible.color" v-model="color" @input="colorChange" />
            </div>
          </div>
        </div>
      </BjValidationItem>
      <!-- <BjValidationItem v-if="param.style === 0" label-align="left" rules="required" label="顶部背景" v-bind="layout">
        <div :class="$style.img" class="mb-10">
          <img v-if="param.background.cover" :src="param.background.cover" alt="" />
        </div>
        <BjButton class="btn-default" @click="onAddBack()"><i class="ri-upload-line left" />选择图片</BjButton>
        <div class="tip mt-10">建议尺寸780px*440px；大小不超过2M；格式支持JPG、PNG。</div>
      </BjValidationItem> -->
      <BjValidationItem v-if="param.style === 3" label-align="left" label="背景文案" v-bind="layout">
        <a-textarea
          v-model="param.back_tip"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          placeholder="请输入背景文案，支持换行。"
          :max-length="500"
        />
      </BjValidationItem>
      <BjInput
        v-if="param.style !== 3 && param.style !== 4"
        v-model="param.tip"
        allow-clear
        label-align="left"
        label="搜索文案"
        rules="required"
        v-bind="layout"
        @change="onChange"
      />

      <BjValidationItem v-if="param.style === 4" rules="required" label="导航配置">
        <div :class="$style.box">
          <div
            :class="$style.boxAdd"
            :style="param.nav.length === 5 ? 'cursor: not-allowed' : null"
            @click="onNavAdd()"
          >
            <i class="ri-add-line mr-5" />
            添加导航
          </div>
          <div v-if="param.nav.length" :class="$style.line" />
          <div v-for="(item, index) in param.nav" :key="item.id" :class="$style.boxEdit">
            <div :class="$style.img">
              <img :src="item.data.cover" alt="" />
            </div>
            <div :class="$style.text">
              <div class="ellipsis-width">标题：{{ item.title }}</div>
              <div class="ellipsis-width">
                跳转：<span class="primary">{{ item.link.label }}</span>
              </div>
            </div>
            <div :class="$style.btn">
              <i class="ri-edit-2-line" @click="onNavEdit(item, index)" />
              <i class="ri-delete-bin-7-line" @click="onNavDelete(index)" />
            </div>
          </div>
        </div>
        <p class="tip mt-10">导航最多设置5条，超过5条将不能再次添加。</p>
      </BjValidationItem>

      <BjValidationItem rules="required" label="轮播图片">
        <div :class="$style.box">
          <div :class="$style.boxAdd" :style="param.data.length === 10 ? 'cursor: not-allowed' : null" @click="onAdd()">
            <i class="ri-add-line mr-5" />
            添加轮播
          </div>
          <div v-if="param.data.length" :class="$style.line" />
          <div v-for="(item, index) in param.data" :key="item.id" :class="$style.boxEdit">
            <div :class="$style.img">
              <img :src="item.data.cover" alt="" />
            </div>
            <div :class="$style.text">
              <div class="ellipsis-width">标题：{{ item.title }}</div>
              <div class="ellipsis-width">
                跳转：<span class="primary">{{ item.link.label }}</span>
              </div>
            </div>
            <div :class="$style.btn">
              <i class="ri-edit-2-line" @click="onEdit(item, index)" />
              <i class="ri-delete-bin-7-line" @click="onDelete(index)" />
            </div>
          </div>
        </div>
        <p class="tip mt-10">轮播图片最多设置10条，超过10条将不能再次添加。</p>
      </BjValidationItem>
    </BjForm>
    <bj-modal
      :title="title"
      :visible="editVisible"
      :width="550"
      :body-style="{ height: '520px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <BjForm ref="edit">
          <BjInput
            v-model="edit.title"
            label-align="left"
            v-bind="layout"
            rules="required"
            tooltips
            placeholder="请输入轮播标题"
            label="轮播标题"
          />
        </BjForm>
        <BjValidationItem rules="required" label-align="left" label="轮播图片" v-bind="layout">
          <!-- <BjButton class="btn-default" @click="onAddImg()">
            <i class="ri-upload-line left" />
            选择图片
          </BjButton>
          <div v-if="edit.data && edit.data.cover" :class="$style.img">
            <img :src="edit.data.cover" alt="" />
          </div> -->
          <selectMaterial :data="edit.data" :allow="['image']" @change="swiperChange" />
          <p class="tip mt-10">轮播图片建议尺寸700px*240px；大小不超过2M；格式支持JPG、PNG。</p>
        </BjValidationItem>
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddLink()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="edit.link" :class="$style.link">{{ edit.link.label }}</p>
          <p class="tip mt-10">请选择点击轮播图片需要跳转的链接。</p>
        </BjValidationItem>
      </div>
    </bj-modal>

    <bj-modal
      :title="titleNav"
      :visible="navVisible"
      :width="550"
      :body-style="{ height: '520px' }"
      @ok="navHandleOk"
      @cancel="navHandleCancel"
    >
      <div :class="$style.modal">
        <BjForm ref="edit">
          <BjInput
            v-model="editNav.title"
            label-align="left"
            v-bind="layout"
            rules="required"
            tooltips
            placeholder="请输入导航标题"
            label="导航标题"
          />
        </BjForm>
        <BjValidationItem rules="required" label-align="left" label="导航图标" v-bind="layout">
          <selectMaterial :data="editNav.data" :allow="['image']" @change="navImgChange" />
          <p class="tip mt-10 mb-0">导航图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
        </BjValidationItem>
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddNavLink()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="editNav.link" :class="$style.link">{{ editNav.link.label }}</p>
          <p class="tip mt-10">请选择点击导航图标需要跳转的链接。</p>
        </BjValidationItem>
      </div>
    </bj-modal>
    <!-- <chooseMaterial :visible.sync="chooseVisible" :allow="['image']" @getChoose="getChoose" /> -->
    <chooseLink :visible.sync="linkVisible" :data="edit.link" @change="getLink" />
    <chooseLink :visible.sync="linkNavVisible" :data="editNav.link" @change="getNavLink" />
  </div>
</template>

<script>
import _ from 'lodash'
import { Chrome } from 'vue-color'

import chooseLink from '@/components/chooseLink'
// import chooseMaterial from '@/components/chooseMaterial'
import selectMaterial from '@/components/selectMaterial'

export default {
  name: 'heads',
  components: {
    // chooseMaterial,
    chooseLink,
    selectMaterial,
    'chrome-picker': Chrome,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {}, // 组件变量 存data
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      // chooseVisible: false,
      editVisible: false,
      linkVisible: false,
      navVisible: false,
      linkNavVisible: false,
      edit: {
        // 弹窗变量
        data: {},
        link: null,
        title: null,
      },
      editNav: {
        // 弹窗变量
        data: {},
        link: null,
        title: null,
      },
      editIndex: null,
      editNavIndex: null,
      title: null, // 弹窗title
      // isBack: false, // 是否是选择顶部背景
      color: {
        hex8: '#1470DF',
      },
      visible: {
        color: false,
      },
      titleNav: null,
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
        this.color.hex8 = val.background_color
      },
      immediate: true,
    },
  },
  methods: {
    backChange(data) {
      this.param.background = data[0]
    },
    swiperChange(data) {
      this.edit.data = { type: 'image', ...data[0] }
    },
    onNavAdd() {
      if (this.param.nav.length > 4) {
        return
      }
      this.editNav = {
        data: {},
        link: null,
        title: null,
      }
      this.editNavIndex = null
      this.titleNav = '添加导航'
      this.navVisible = true
    },
    onNavEdit(item, index) {
      this.titleNav = '编辑导航'
      this.editNavIndex = index
      this.editNav = _.cloneDeep(item)
      this.navVisible = true
    },
    onNavDelete(index) {
      this.param.nav.splice(index, 1)
    },
    navImgChange(data) {
      this.editNav.data = data[0]
    },
    onAddNavLink() {
      this.linkNavVisible = true
    },
    getNavLink(data) {
      this.editNav.link = _.cloneDeep(data)
    },
    navHandleOk() {
      if (!this.editNav.title || !this.editNav.link || !this.editNav.data.type) {
        this.$message.error('请把信息填完整后提交')
        return
      }
      if (this.editNavIndex !== null) {
        this.param.nav.splice(this.editNavIndex, 1, this.editNav)
      } else {
        this.param.nav.push(this.editNav)
      }
      this.onChange()
      this.navHandleCancel()
    },
    navHandleCancel() {
      this.navVisible = false
    },
    colorChange() {
      this.param.background_color = this.color.hex8
      this.onChange()
    },
    onChange() {
      this.$emit('change', this.param)
    },
    // getChoose(data) {
    //   if (this.isBack) {
    //     this.param.background = { type: 'image', ...data[0] }
    //     this.onChange()
    //   } else {
    //     this.edit.data = { type: 'image', ...data[0] }
    //   }
    // },
    getLink(data) {
      this.edit.link = _.cloneDeep(data)
    },
    onAdd() {
      if (this.param.data.length > 9) {
        return
      }
      this.editVisible = true
      this.edit = {
        data: {},
        link: null,
        title: null,
      }
      this.editIndex = null
      this.title = '添加轮播'
    },
    // onAddImg() {
    //   this.isBack = false
    //   this.chooseVisible = true
    // },
    onAddLink() {
      this.linkVisible = true
    },
    onEdit(item, index) {
      this.title = '编辑轮播'
      this.editIndex = index
      this.edit = _.cloneDeep(item)
      this.editVisible = true
    },
    onDelete(index) {
      this.param.data.splice(index, 1)
    },
    handleOk() {
      if (!this.edit.title || !this.edit.link || !this.edit.data.type) {
        this.$message.error('请把信息填完整后提交')
        return
      }
      if (this.editIndex !== null) {
        this.param.data.splice(this.editIndex, 1, this.edit)
      } else {
        this.param.data.push(this.edit)
      }
      this.onChange()
      this.handleCancel()
    },
    handleCancel() {
      this.editVisible = false
    },
    onChooseColor() {
      this.visible.color = !this.visible.color
    },
    pickOut() {
      this.visible.color = false
    },
  },
}
</script>

<style lang="less" module>
.modal {
  .img {
    position: relative;
    width: 70px;
    height: 70px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .link {
    margin-bottom: 0;
    color: @primary-color;
  }

  .tip {
    color: #5c5c5c;
    font-size: 12px;
  }
}

.head {
  :global {
    .ant-form-item {
      margin-bottom: 20px;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;

    .line {
      width: 100%;
      margin: 10px 0;
      border-top: 1px solid #eee;
    }
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;

    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .img {
    position: relative;
    width: 70px;
    height: 70px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .text {
      flex: 1;
      padding: 10px;
      font-size: 12px;

      div {
        height: 25px;
        line-height: 25px;
      }
    }

    .btn {
      width: 60px;

      i {
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
}

.color {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eee;

  .color-box {
    z-index: 9;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;

    i {
      color: #fff;
      font-size: 14px;
    }
  }

  .pick {
    position: absolute;
    top: 24px;
    z-index: 99;
  }
}
</style>
