<template>
  <div :class="$style.content" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" class="ellipsis" :class="$style.more">
        <span>{{ data.enter }} </span>
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div v-for="(item, index) in data.data" :key="index">
      <div v-if="item.cover_type === 0 || item.cover_type === 2" :class="$style.simple">
        <div :class="$style.box">
          <div :class="$style.text">
            <p :class="$style.title" class="ellipsis-width2">{{ item.title }}</p>
            <p>
              <span :class="$style.name">{{ item.author.real_name }}</span>
              <span :class="$style.praise">
                <i class="ri-thumb-up-line" />
                {{ item.praise_num }}</span
              >
              <span :class="$style.eye">
                <i class="ri-eye-line" />
                {{ item.read_num }}</span
              >
            </p>
          </div>
          <div :class="$style.img">
            <img :class="$style.cover" :src="item.cover_atlas[0].cover" alt="" />
            <img
              v-if="item.content_type === 'video'"
              :class="$style.btn"
              :src="oss + '/manage/icon/default/video.png'"
            />
            <img
              v-if="item.content_type === 'audio'"
              :class="$style.btn"
              :src="oss + '/manage/icon/default/audio.png'"
            />
            <img :class="$style.btn" :src="oss + '/manage/icon/default/video.png'" />
          </div>
        </div>
      </div>

      <div v-if="item.cover_type === 1" :class="$style.many">
        <p :class="$style.title" class="ellipsis-width">{{ item.title }}</p>
        <div :class="$style.box">
          <img
            v-for="(imgItem, imgIndex) in item.cover_atlas"
            :key="imgIndex"
            :class="$style.item"
            :src="imgItem.cover"
          />
        </div>
        <p :class="$style.text">
          <span :class="$style.name">{{ item.author.real_name }}</span>
          <span :class="$style.praise">
            <i class="ri-thumb-up-line" />
            {{ item.praise_num }}</span
          >
          <span :class="$style.eye">
            <i class="ri-eye-line" />
            {{ item.read_num }}</span
          >
        </p>
      </div>

      <div v-if="item.cover_type === 3" :class="$style.big">
        <p :class="$style.title" class="ellipsis-width">{{ item.title }}</p>
        <div :class="$style.box">
          <img :class="$style.img" :src="item.cover_atlas[0].cover" alt="" />
          <img v-if="item.content_type === 'video'" :class="$style.btn" :src="oss + '/manage/icon/default/video.png'" />
          <img v-if="item.content_type === 'audio'" :class="$style.btn" :src="oss + '/manage/icon/default/audio.png'" />
        </div>
        <p :class="$style.text">
          <span :class="$style.name">{{ item.author.real_name }}</span>
          <span :class="$style.praise">
            <i class="ri-thumb-up-line" />
            {{ item.praise_num }}</span
          >
          <span :class="$style.eye">
            <i class="ri-eye-line" />
            {{ item.read_num }}</span
          >
        </p>
      </div>
    </div>
    <div v-if="!data.data.length">
      <div v-for="(item, index) in fakeData.data" :key="index">
        <div v-if="item.cover_type === 0 || item.cover_type === 2" :class="$style.simple">
          <div :class="$style.box">
            <div :class="$style.text">
              <p :class="$style.title" class="ellipsis-width2">{{ item.title }}</p>
              <p>
                <span :class="$style.name">{{ item.author.real_name }}</span>
                <span :class="$style.praise">
                  <i class="ri-thumb-up-line" />
                  {{ item.praise_num }}</span
                >
                <span :class="$style.eye">
                  <i class="ri-eye-line" />
                  {{ item.read_num }}</span
                >
              </p>
            </div>
            <div :class="[$style.img, $style.nodata2]">
              <img :src="item.cover_atlas[0].cover" alt="" />
            </div>
          </div>
        </div>

        <div v-if="item.cover_type === 1" :class="$style.many">
          <p :class="$style.title" class="ellipsis-width">{{ item.title }}</p>
          <div :class="$style.box">
            <div v-for="(imgItem, imgIndex) in item.cover_atlas" :key="imgIndex" :class="[$style.item, $style.nodata]">
              <img :src="imgItem.cover" alt="" />
            </div>
          </div>
          <p :class="$style.text">
            <span :class="$style.name">{{ item.author.real_name || ' ' }}</span>
            <span :class="$style.praise">
              <i class="ri-thumb-up-line" />
              {{ item.praise_num }}</span
            >
            <span :class="$style.eye">
              <i class="ri-eye-line" />
              {{ item.read_num }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { manageService } from '@/service'

const service = new manageService()

export default {
  name: 'contentList',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fakeData: {
        type: 'content_list',
        icon: 'ri-list-check-2',
        label: '内容列表',
        title: '内容列表', // 标题
        title_show: 1, // 是否显示标题
        enter: '更多', // 入口文案
        enter_show: 1, // 是否显示入口
        exist: 1,
        sort: 0, // 排序
        load_mode: 0, //加载方式
        source: 0, // 来源
        position: 'any',
        data: [
          {
            cloud_id: '1',
            collect_num: 0,
            comment_num: 0,
            content_type: 'image',
            cover_type: 1,
            distance: '',
            praise_num: 43,
            read_num: 44,
            title: '内容的标题',
            type_id: '2',
            type_name: '人物事迹',
            cover: {
              cover: this.oss + '/manage/media/assets/icon.png',
              path: this.oss + '/manage/media/assets/icon.png',
              type: 'image',
            },
            cover_atlas: [
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
            ],
            author: {
              avatar: this.oss + '/manage/media/assets/icon.png',
              id: '88f56f48c8ca41d88df62c9aef0bea7e',
              real_name: '作者名称',
            },
          },
          {
            cloud_id: '1',
            collect_num: 0,
            comment_num: 0,
            content_type: 'image',
            cover_type: 0,
            distance: '',
            praise_num: 12,
            read_num: 33,
            title: '内容的标题',
            type_id: '2',
            type_name: '人物事迹',
            cover: {
              cover: this.oss + '/manage/media/assets/icon.png',
              path: this.oss + '/manage/media/assets/icon.png',
              type: 'image',
            },
            cover_atlas: [
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
              {
                cover: this.oss + '/manage/media/assets/icon.png',
                path: this.oss + '/manage/media/assets/icon.png',
                type: 'image',
              },
            ],
            author: {
              avatar: this.oss + '/manage/media/assets/icon.png',
              id: '88f56f48c8ca41d88df62c9aef0bea7e',
              real_name: '作者名称',
            },
          },
        ], // 详情见内容数据
      },
    }
  },
  computed: {
    ...mapState(['editor']),
  },
  created() {
    if (this.editor.page_type !== 'poc' && !this.data.data.length) {
      this.getHotList()
    }
    if (this.editor.page_type === 'poc' && !this.data.data.length) {
      this.getList()
    }
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
    async getList() {
      try {
        const { data } = await service.getEditorList({
          page_type: this.editor.page_type,
          poc_id: this.editor.poc_id,
        })
        this.$emit('edit', { ...this.data, data: data })
      } catch (e) {}
    },
    async getHotList() {
      try {
        const { data } = await service.getEditorList({
          page_type: this.editor.page_type,
        })
        this.$emit('edit', { ...this.data, data: data })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.content {
  min-height: 120px;

  .head {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 14px 20px;

    .title {
      flex: 1;
      color: #000;
      font-size: 18px;
    }

    .more {
      max-width: 150px;
      padding-right: 15px;
      color: #5c5c5c;
      font-size: 12px;
      line-height: 25px;
      text-align: right;

      i {
        position: absolute;
        right: 20px;
        font-size: 17px;
        vertical-align: -4px;
      }
    }
  }

  .simple {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;

    .box {
      display: flex;

      .text {
        flex: 1;
        padding-right: 10px;

        .title {
          height: 40px;
          color: #000;
        }

        .name {
          color: #5c5c5c;
          font-size: 12px;
        }

        .eye {
          float: right;
          margin-right: 5px;
          color: #bbb;

          i {
            color: #bbb;
            font-size: 17px;
          }
        }

        .praise {
          float: right;
          color: #bbb;

          i {
            color: #bbb;
            font-size: 17px;
          }
        }
      }

      .img {
        position: relative;
        width: 110px;
        height: 80px;

        .cover {
          width: 110px;
          height: 80px;
          object-fit: cover;
        }

        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          height: 24px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .many {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    /* stylelint-disable-next-line no-descending-specificity */
    .title {
      color: #000;
      font-size: 16px;
    }

    .box {
      display: flex;
      justify-content: space-between;

      .item {
        width: 112px;
        height: 80px;
        object-fit: cover;
      }
    }

    .text {
      height: 21px;
      margin: 10px 0 0 0;

      .name {
        color: #5c5c5c;
        font-size: 12px;
      }

      .eye {
        float: right;
        margin-right: 5px;
        color: #bbb;

        i {
          color: #bbb;
          font-size: 17px;
        }
      }

      .praise {
        float: right;
        color: #bbb;

        i {
          color: #bbb;
          font-size: 17px;
        }
      }
    }
  }

  .big {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    /* stylelint-disable-next-line no-descending-specificity */
    .title {
      color: #000;
      font-size: 16px;
    }

    .box {
      position: relative;
      width: 350px;
      height: 200px;
      margin: 0 auto;

      .img {
        width: 350px;
        height: 200px;
        object-fit: cover;
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
      }
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .text {
      margin: 10px 0 0 0;
      /* stylelint-disable-next-line no-descending-specificity */
      .name {
        color: #5c5c5c;
        font-size: 12px;
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .eye {
        float: right;
        margin-right: 5px;
        color: #bbb;
        /* stylelint-disable-next-line no-descending-specificity */
        i {
          color: #bbb;
          font-size: 17px;
        }
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .praise {
        float: right;
        color: #bbb;
        /* stylelint-disable-next-line no-descending-specificity */
        i {
          color: #bbb;
          font-size: 17px;
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    border-radius: 6px;
  }
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;

  img {
    width: 40px;
    height: 40px;
  }
}

.nodata:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.nodata:nth-child(3) {
  border-radius: 0 6px 6px 0;
}

.nodata2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  border-radius: 6px;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
