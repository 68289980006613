<template>
  <div :class="$style.venue" @click="onChoose()">
    <p v-if="data.title_show || data.enter_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
      <span v-if="data.enter_show" :class="$style.more">
        更多
        <i class="ri-arrow-right-s-line" />
      </span>
    </p>
    <div :class="$style.box">
      <div v-for="(item, index) in list" :key="index" :class="$style.item">
        <div v-if="item.isDemo" :class="$style.demo">
          <img :src="item.cover" />
        </div>
        <img v-else :src="item.cover.cover" />
        <span v-if="item.isDemo" :class="$style.localdemo">0km</span>
        <span v-else :class="$style.local">0km</span>
        <p class="ellipsis" :class="$style.name">{{ item.name }}</p>
        <p class="ellipsis" :class="$style.summary">{{ item.tag }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'venue',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      demo: [
        {
          isDemo: true,
          cover: this.oss + '/manage/media/assets/icon.png',
          name: '场馆名称',
          tag: '场馆标签',
        },
      ],
    }
  },
  computed: {
    list() {
      return this.data.source === 1
        ? this.data.data.length
          ? this.data.data
          : this.demo.concat(this.demo).concat(this.demo).concat(this.demo)
        : this.data.hot
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.venue {
  padding: 20px;
  overflow: hidden;

  .head {
    margin: 0;
    padding-bottom: 14px;
    height: 46px;

    .title {
      display: inline-block;
      color: #000;
      font-size: 18px;
    }

    .more {
      float: right;
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .box {
    display: flex;

    .item {
      width: 110px;
      margin-right: 10px;
      position: relative;

      img {
        width: 110px;
        height: 140px;
        object-fit: cover;
        border-radius: 6px;
      }

      .name {
        margin-top: 1px;
        margin-bottom: 6px;
        font-size: 14px;
      }

      .summary {
        color: #e89126;
        font-size: 10px;
      }
    }

    .demo {
      width: 110px;
      height: 140px;
      border-radius: 6px;
      background: #eee;
      text-align: center;
      line-height: 140px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 6px;
        object-fit: cover;
      }
    }

    .local {
      position: absolute;
      top: 115px;
      right: 10px;
      color: #fff;
      font-size: 12px;
    }

    .localdemo {
      position: absolute;
      top: 115px;
      right: 10px;
      color: #bbb;
      font-size: 12px;
    }
  }
}
</style>
