<template>
  <div :class="$style.nav" @click="onChoose()">
    <p v-if="data.title_show" :class="$style.head">
      <span v-if="data.title_show" class="ellipsis" :class="$style.title">{{ data.title }}</span>
    </p>
    <div v-if="showData.length" :class="$style.box">
      <div v-for="(item, index) in showData" :key="index" :class="$style.item" :style="width">
        <img v-if="item.data.cover" :src="item.data.cover" />
        <div v-else :class="$style.block" />
        <p :class="$style.title" class="ellipsis-width">{{ item.title }}</p>
      </div>
      <div v-if="showData.length < data.data.length" :class="$style.docMode">
        <span :class="$style.doc"><span :class="$style.docactive" /></span>
      </div>
    </div>
    <div v-else :class="$style.box">
      <div v-for="(item, index) in shwoNum" :key="index" :style="width" :class="$style.item">
        <div :class="$style.img" />
        <p :class="$style.title">导航标题</p>
      </div>
    </div>
    <div v-if="showData.length > shwoNum || !showData.length" :class="$style.btn">
      <div :class="$style.bg">
        <div :class="$style.active" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // map + style + row_num 对应渲染dom个数
      map: {
        map00: 5,
        map01: 4,
        map02: 3,
        map10: 10,
        map11: 8,
        map12: 6,
      },
      default: [
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
        { title: '导航标题' },
      ],
    }
  },
  computed: {
    showData() {
      return this.data.data.slice(0, this.map['map' + this.data.style + this.data.row_num]) || this.default
    },
    shwoNum() {
      return this.map['map' + this.data.style + this.data.row_num]
    },
    width() {
      return { width: this.data.row_num === 0 ? '20%' : this.data.row_num === 1 ? '25%' : '33.33%' }
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.nav {
  .head {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 14px 20px;

    .title {
      flex: 1;
      color: #000;
      font-size: 18px;
    }

    .more {
      color: #5c5c5c;
      font-size: 12px;
      line-height: 25px;
      text-align: right;

      i {
        font-size: 17px;
        vertical-align: -4px;
      }
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .doc-mode {
      width: 100%;
      height: 20px;
      line-height: 10px;
      text-align: center;

      .doc {
        display: inline-block;
        width: 20px;
        height: 2px;
        background-color: #eee;
        border-radius: 100px;

        .docactive {
          float: left;
          width: 10px;
          height: 2px;
          background: @primary-color;
          border-radius: 100px;
        }
      }
    }

    .item {
      width: 20%;
      text-align: center;

      img {
        width: 40px;
        height: 40px;
        margin-top: 20px;
        object-fit: cover;
      }

      .title {
        margin-top: 10px;
        margin-bottom: 0;
        padding: 0 3px;
        color: #5c5c5c;
        font-size: 12px;
      }

      .img {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-top: 20px;
        background: #eee;
        border-radius: 100%;
      }

      .block {
        width: 40px;
        height: 40px;
        background: #eee;
      }
    }
  }

  .btn {
    text-align: center;

    .bg {
      display: inline-block;
      width: 20px;
      height: 2px;
      background: #eee;
      border-radius: 100px;

      .active {
        width: 10px;
        height: 2px;
        background: @primary-color;
        border-radius: 100px;
      }
    }
  }
}
</style>
